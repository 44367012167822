import React from "react";
import "../Styles/Solution.css";
import img1sol from "../Assets/sol/Group.png";
import img1sol12 from "../Assets/sol1pic.png";
import "bootstrap/dist/css/bootstrap.css";
import img3 from "../Assets/images/client_connect_app_img.png";
import img4 from "../Assets/images/client_keyboard_app.png";
import { Link } from "react-router-dom";
import Flag from "./Flag";
import Some from "../Assets/notedownsome.png";
import Some2 from "../Assets/some2.png";
import Some3 from "../Assets/some3.png";
import Some4 from "../Assets/some4.png";
import Some5 from "../Assets/some5.png";
import Some6 from "../Assets/some6.png";
import Card1 from "../Assets/cardimg1.png";
import Card2 from "../Assets/card2.png";
import Card3 from "../Assets/card3.png";
import Card4 from "../Assets/card4.png";
import Card5 from "../Assets/card5.png";
import Card6 from "../Assets/card6.png";
import Card7 from "../Assets/card7.png";
import Card8 from "../Assets/card8.png";
import Card9 from "../Assets/card9.png";
import NewFaq from "./NewFaq";
function Solution1() {
  return (
    <div style={{ overflow: 'hidden' }}>
      <div className="banner_Solution1">
        <div className="Solutionbanner1_section">
          <h2>
            Empowering Digital Transformation with Custom Portal Solutions
          </h2>
          <p>
            Custom portal solutions are revolutionizing digital transformation
            by empowering businesses to streamline processes, enhance
            collaboration, and deliver personalized experiences to stakeholders.
          </p>
        </div>
      </div>
      <section className="main_body_soltions">
        <section className="section1_solution">
          <div className="section1_content">
            <h3 style={{ color: "#3D4DAE" }}>
              CRM(Customer Relationship Management)
            </h3>
            <p style={{ color: "#686868", fontSize: "14px" }}>
              ClientConnect, developed by Meetintel ltd, is a
              state-of-the-art CRM (Customer Relationship Management) software
              integrated with advanced AI features. It is designed to empower
              businesses with efficient customer management and engagement
              capabilities, enabling them to stay competitive and relevant in
              today's dynamic business environment. ClientConnect offers a
              comprehensive suite of tools and functionalities to streamline
              customer interactions, optimize sales processes, and drive
              business growth.
            </p>
          </div>
          <div className="section2_img">
            <img src={img1sol12} className="imgsizing_sol2" />
          </div>
        </section>
        <section className="section2_solution">
          <h2
            style={{
              color: "#3D4DAE",
              marginBottom: "40px",
              fontWeight: "bolder",
            }}
          >
            Features of ClientConnect
          </h2>
          <div className="card_container_solu1">
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intelligent Customer Engagement
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Utilize AI algorithms to gain insights into customer behavior,
                preferences, and needs. Leverage chatbots and virtual assistants
                for personalized and round-the-clock customer support.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Enhanced Sales Automation
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Automate sales processes with intelligent lead scoring,
                prioritization, and predictive analytics. Identify cross-selling
                and upselling opportunities for increased revenue generation.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Advanced Data Analytics
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Extract actionable insights from customer data with AI-driven
                analytics. Track key performance indicators, monitor customer
                trends, and make informed business decisions.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Seamless Integration
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                }}
              >
                Integrate with existing systems and platforms, ensuring a
                unified view of customer data across touchpoints.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Workflow Automation
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Streamline and automate routine tasks to improve team
                productivity and handle customer inquiries efficiently.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Scalability and Customizability
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Tailor the solution to meet the specific requirements of
                businesses of all sizes. Customize workflows, data fields, and
                user interfaces.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Data Security and Compliance
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Adhere to industry standards and implement robust security
                measures to protect sensitive customer information and ensure
                compliance with data protection regulations.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intuitive User Experience
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                User-friendly interface with interactive dashboards for easy
                navigation and task management. Quick onboarding and intuitive
                learning curve.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Meetintel ltd to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Data Security and Compliance
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Adhere to industry standards and implement robust security
                measures to protect sensitive customer information and ensure
                compliance with data protection regulations.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intuitive User Experience
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                User-friendly interface with interactive dashboards for easy
                navigation and task management. Quick onboarding and intuitive
                learning curve.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Meetintel ltd to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="btn_sol1">
              <Link to={"/Contact"}>
                <button className="btn_sub_col1">Request A Demo</button>
              </Link>
            </div>
          </div>
        </section>
      </section>
      <section
        style={{
          backgroundColor: "#F5F5F5",
          paddingTop: "0px",
          paddingBottom: "30px",


        }}
        className="sixcard3">
        <div className="container ppp" style={{ maxWidth: "1200px" }}>
          <h3
            style={{
              color: "#3D4DAE",
              fontSize: "40px",
              fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              marginLeft: "20px",
              paddingTop: '60px',
              marginBottom: "40px",
            }}
          >
            Benefits of ClientConnect
          </h3>

          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some} style={{ marginBottom: "15px" }} />
                <h4>Improved Customer Satisfaction and Loyalty</h4>
                <p>
                  Personalized engagement and round-the-clock support enhance customer satisfaction, leading to increased loyalty and retention.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some2} style={{ marginBottom: "15px" }} />
                <h4>Increased Sales Effectiveness</h4>
                <p>
                  Automation and AI-powered analytics enable efficient lead management, identify opportunities, and improve sales performance.
                </p>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some6} style={{ marginBottom: "15px" }} />
                <h4>Data-Driven Decision Making</h4>
                <p>
                  Actionable insights from data analytics support informed business decisions, helping businesses adapt their strategies for better customer outcomes.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some3} style={{ marginBottom: "15px" }} />
                <h4>Increased Sales Effectiveness</h4>
                <p>
                  Automation and AI-powered analytics enable efficient lead management, identify opportunities, and improve sales performance.
                </p>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section>
        <div className="container" style={{ maxWidth: '1060px', marginTop: '6%' }} >
          <h3 style={{ color: '#3D4DAE', fontSize: "40px", fontWeight: '700', marginLeft: '2%' }}>Industries and How ClientConnect Helps Them</h3>
          <p style={{ color: '#686868', fontSize: '20px', marginLeft: '2%' }}>E-commerce: ClientConnect enables personalized engagement and targeted marketing campaigns, helping e-commerce businesses improve customer satisfaction and increase conversions.</p>
          <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="col " style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className="cardsec">
                <img src={Card1} />
                <div style={{ textAlign: 'center', padding: '12px' }}>
                  <h4 style={{ color: '#3D4DAE', fontSize: "24px", fontWeight: '700' }}>E-commerce:</h4>
                  <p style={{ color: '#686868', fontSize: '15px' }}>
                    ClientConnect enables personalized engagement and targeted
                    marketing campaigns, helping e-commerce businesses improve
                    customer satisfaction and increase conversions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div className="cardsec">
              <img src={Card2} />
              <div style={{ textAlign: 'center', padding: '12px' }}>
                <h4 style={{ color: '#3D4DAE', fontSize: "24px", fontWeight: '700' }}>Financial Services</h4>
                <p style={{ color: '#686868', fontSize: '15px' }}>
                  AI-powered analytics and automation enhance lead management and sales effectiveness, enabling financial service providers to optimize their sales processes and drive revenue growth.
                </p>
              </div>
            </div></div>
            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div className="cardsec">
              <img src={Card3} />
              <div style={{ textAlign: 'center', padding: '12px' }}>
                <h4 style={{ color: '#3D4DAE', fontSize: "24px", fontWeight: '700' }}>Telecom</h4>
                <p style={{ color: '#686868', fontSize: '15px' }}>
                  ClientConnect can help telecom companies enhance their customer engagement through personalized interactions, AI-powered support, and targeted marketing campaigns. It enables streamlined order management and billing.
                </p>
              </div>
            </div></div>
          </div>
          <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="col " style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className="cardsec">
                <img src={Card4} />
                <div style={{ textAlign: 'center', padding: '12px' }}>
                  <h4 style={{ color: '#3D4DAE', fontSize: "24px", fontWeight: '700' }}>Healthcare</h4>
                  <p style={{ color: '#686868', fontSize: '15px' }}>
                    ClientConnect's personalized customer engagement and streamlined workflows improve patient experiences, appointment management, and healthcare service delivery.
                  </p>
                </div>
              </div>
            </div>
            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div className="cardsec">
              <img src={Card5} />
              <div style={{ textAlign: 'center', padding: '12px' }}>
                <h4 style={{ color: '#3D4DAE', fontSize: "24px", fontWeight: '700' }}>Hospitality</h4>
                <p style={{ color: '#686868', fontSize: '15px' }}>
                  ClientConnect's integration capabilities help streamline customer interactions across various touchpoints, enhancing guest experiences and loyalty in the hospitality industry.
                </p>
              </div>
            </div></div>
            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div className="cardsec">
              <img src={Card6} />
              <div style={{ textAlign: 'center', padding: '12px' }}>
                <h4 style={{ color: '#3D4DAE', fontSize: "24px", fontWeight: '700' }}>Education</h4>
                <p style={{ color: '#686868', fontSize: '15px' }}>
                  In the education sector, ClientConnect facilitates effective student enrollment, personalized communication with parents, and streamlined administrative processes. It provides insights into student performance.

                </p>
              </div>
            </div></div>
          </div>
          <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="col " style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className="cardsec">
                <img src={Card7} />
                <div style={{ textAlign: 'center', padding: '12px' }}>
                  <h4 style={{ color: '#3D4DAE', fontSize: "24px", fontWeight: '700' }}>Real Estate</h4>
                  <p style={{ color: '#686868', fontSize: '15px' }}>
                    ClientConnect can optimize customer relationship management in the real estate industry by automating lead nurturing, managing property inquiries, and tracking customer preferences. It streamlines sales processes.          </p>
                </div>
              </div>
            </div>
            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div className="cardsec">
              <img src={Card8} />
              <div style={{ textAlign: 'center', padding: '12px' }}>
                <h4 style={{ color: '#3D4DAE', fontSize: "24px", fontWeight: '700' }}>Automotive</h4>
                <p style={{ color: '#686868', fontSize: '15px' }}>
                  With ClientConnect, automotive companies can deliver exceptional customer experiences throughout the vehicle ownership lifecycle. It enables efficient management of service requests and personalized communication.
                </p>
              </div>
            </div></div>
            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div className="cardsec">
              <img src={Card9} />
              <div style={{ textAlign: 'center', padding: '12px' }}>
                <h4 style={{ color: '#3D4DAE', fontSize: "24px", fontWeight: '700' }}>E-commerce:</h4>
                <p style={{ color: '#686868', fontSize: '15px' }}>
                  ClientConnect enables personalized engagement and targeted
                  marketing campaigns, helping e-commerce businesses improve
                  customer satisfaction and increase conversions.
                </p>
              </div>
            </div></div>
          </div>
        </div>
      </section>

      <div className=" d-none d-sm-block ">
        <div className="empowering_business mt-5 ">
          <div className="text-center">
            <h3 className="text-askari-primary fw-bold" style={{ color: 'white' }}>
              Empowering Businesses with AI-Powered CRM Capabilities
            </h3>
            <p className="mt-3" style={{ color: 'white' }}>
              ClientConnect from Meetintel ltd empowers businesses across
              industries with its AI-powered CRM capabilities. By providing
              personalized customer engagement, automating sales processes,
              offering advanced data analytics, and ensuring seamless
              integration, ClientConnect helps businesses unlock growth and
              success in their respective industries.
            </p>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
}

export default Solution1;

import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/ProductEng.css";
import { HashLink } from "react-router-hash-link";
import left2_ecom from "../Assets/mask_ecom.png";
import Technologywework from "../Components/Technologywework.jsx";
import Endtwo from "../Components/Endtwo.jsx";
import pic1 from "../Assets/pic1.png";
import img1 from "../Assets/1.jpg";
import img2 from "../Assets/2.jpg";
import img3 from "../Assets/3.jpg";
import pic3 from "../Assets/pic3.png";
import pic4 from "../Assets/pic4.png";
import pic5 from "../Assets/pic5.png";
import autoparts from "../Assets/autoparts.png";
import pharma from "../Assets/pharma.png";
import apple from "../Assets/apple.png";
import beauty from "../Assets/beauty.png";
import car from "../Assets/car.png";


import check from "../Assets/check.png";

import ecommerce_capab from "../Assets/ecommerce_capab.png";
import pe1 from "../Assets/pe1.png";
import pe2 from "../Assets/pe2.png";
import pe3 from "../Assets/pe3.png";
import pe4 from "../Assets/pe4.png";
import pe5 from "../Assets/pe5.png";
import mainbg from "../Assets/maingbg.png";
import left2 from "../Assets/left2.png";
import microsoft from "../Assets/microsoft.png";
import js from "../Assets/js.png";
import php from "../Assets/php.png";
import salesforce from "../Assets/salesforce.png";
import Mobi from "../Assets/mobi.png";
import odoo from "../Assets/odoo.png";
import netcore from "../Assets/netCore.png";
import Contactsub from "./Contactsub";
import Flag from "./Flag";
import NewFaq from "./NewFaq";

const EcommerceService = () => {
  return (
    <div className="bodycontent" style={{ overflow: 'hidden' }}>
      <div className="bannner-image">
        {/* <p className="text-center text-white ">
         
        </p> */}

        <div className="">
          <p style={{ fontSize: "15px" }}>End to End</p>
          <br></br>
          <h1 style={{ fontWeight: "bolder", marginTop: "3%" }}>
            Ecommerce Development <br />
          </h1>
          <h1 style={{ fontWeight: "bolder", marginTop: "1%" }}>Services</h1>
          <p
            style={{
              fontSize: "13px",
              marginBottom: "2%",
              marginTop: "-20px",
              width: "90%",
            }}
          >
            Elevate your customer experience through a harmonious blend of brand
            objectives and <br />cutting-edge technology stacks like Magento, Shopify,
            and BigCommerce<br />.Unlock the potential of eCommerce<br /> for various
            business models, including B2B, B2C, C2C, and more, by <br />leveraging
            our expertise in custom eCommerce development solutions.
          </p>
          <HashLink to="#contact-us">
            <button className="btn_pro1">Request Consultation</button>
          </HashLink>
        </div>
      </div>
      <div className="row mainsection">
        <div className="col">
          <div class="image-container">
            <img
              src={ecommerce_capab}
              alt="PNG Image"
              class="overlay-image"
              style={{ width: "100%", height: "60%", marginTop: "20%" }}
            />
          </div>
        </div>
        <div className="col servicespara">
          <h3>
            Tailoring Ecommerce <br /> Development to Maximize <br /> Profits
          </h3>
          <p>
            Want to revolutionize your digital presence? We can accelerate your
            business with our custom eCommerce solutions and services. Our
            eCommerce experts can assist you in integrating features,
            customizing the frontend, and developing an e-store from scratch for
            Magento, Shopify, Drupal, and other platforms. At Meetintel  ,
          </p>
          <p>
            we provide bespoke development services to ensure that an eCommerce
            solution meets your business needs. While developing your e-store,
            our developers consider all the aspects related to design, features,
            tech stack, security, integrations, etc., to provide you with a
            fully functional e-store without any bugs or glitches.
          </p>
        </div>
      </div>
      <div className="container">
        <div
          className="align_product"
          style={{ flexDirection: "column" }}
        >
          <h3 style={{ alignSelf: "center" }}>
            Our Ecommerce Development Services and Expertise
          </h3>
          <p style={{ alignSelf: "center" }}>
            Experience the power of our stable, secure, and adaptable eCommerce
            solutions that elevate customer satisfaction, drive sales growth,
            and fuel business expansion!
          </p>
        </div>
      </div>
      <div
        class="image-row container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",


        }}
      >
        {/* <div class="container" >
          <div class="row m-auto" >
            <div class="col-md-2 col-sm-6 m-auto" >
              <div class="ui-card">
                <img src={pe1} />
                <div class="description">
                <h3>Development</h3>
                                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-2 col-sm-6 m-auto">
              <div class="ui-card">
                <img src={pe2} />
                <div class="description">
                <h3>Development</h3>
                                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-2 col-sm-6 m-auto">
              <div class="ui-card">
                <img src={pe3} />
                <div class="description">
                <h3>Development</h3>
                                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="image-row" style={{ marginBottom: '40px' }}>

          <div class="ui-card" style={{ margin: '10px' }}>
            <img src={img1} />
            <div class="description">
              <h3>Ecommerce Store Development</h3>
              <p>We create custom e-stores (Magento, Shopify, Drupal, etc.) for seamless buying experiences, handling diverse domains with ease.</p>

            </div>
          </div>
          <div class="ui-card" style={{ margin: '10px' }}>
            <img src={img2} />
            <div class="description">
              <h3>Ecommerce App Development</h3>
              <p>Use our eCommerce development for unique, professional, and innovative apps that attract and retain your target audience.</p>
            </div>
          </div>
          <div class="ui-card" style={{ margin: '10px' }}>
            <img src={img3} />
            <div class="description">
              <h3>Ecommerce Support & Maintenance</h3>
              <p>
                Enjoy 24/7 support, updates, and maintenance for an always-running eStore.</p>
            </div>
          </div>
          <div class="ui-card" style={{ margin: '10px' }}>
            <img src={img3} />
            <div class="description">
              <h3>Ecommerce Aggregator
              </h3>
              <p>Need a multi-vendor platform? We host diverse brands with our development expertise.          </p>
            </div>
          </div>
          <div class="ui-card" style={{ margin: '10px' }}>
            <img src={img1} />
            <div class="description">
              <h3>B2B And B2C Marketplaces</h3>
              <p>
                Create an Amazon-like marketplace with our eCommerce development for seamless shopping.</p>
            </div>
          </div>
        </div>
      </div>
      <Contactsub />
      <div className="exxx"></div>
      <Technologywework />
      <div className="container mt-5">
        <h1 className="heading">Industries We Serve</h1>
        <h6 className="subHead mb-5">
          We serve a wide range of industries, providing innovative solutions
          tailored to their unique needs.
        </h6>
        <div class="container container-fluid">
          <div class="row">
            <div class="col-md-4 col-sm-12 column border_right_bottom">
              <img src={pic1} />
              <h5 className="name">Fashions & Clothing</h5>
            </div>
            <div class="col-md-4  col-sm-12  column border_right_bottom">
              <img src={pic3} />
              <h5 className="name">Retail</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={autoparts} />
              <h5 className="name">Auto Parts</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={pharma} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={apple} />
              <h5 className="name">Fitness & Nutrition</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={pic5} />
              <h5 className="name">Furniture</h5>
            </div>
          </div>
          <div className="row">
            <div class="col-md-4   col-sm-12 column border_right">
              <img src={pic4} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right">
              <img src={beauty} />
              <h5 className="name">Beauty And Personal Care</h5>
            </div>
            <div class="col-md-4  col-sm-12 column ">
              <img src={car} />
              <h5 className="name">Rental</h5>
            </div>
          </div>
        </div>
      </div>

      <section className="your_patner_pro " >
        <h2>More than Just Ecommerce Development Services</h2>
        <p>
          Development alone is not enough. Therefore, we provide an extensive
          array of professional eCommerce development services that have the
          potential to revolutionize the way your customers perceive your brand
        </p>

        {/* lower cards */}
        <div className="container card_pro_eng_botm" >
          <div className="sub_card_pro_eng_botm" style={{ height: '70%' }}>
            <div className="sub_card_pro_eng_head">
              <img src={require("../Assets/card_sub1.png")} />
            </div>
            <div className="sub_card_pro_eng_footer">
              <center>
                <h5 className="sub_card_heading">Efficiency</h5>
              </center>
              <p className="para_pro_card_eng">
                Our eCommerce professionals analyze your store's strengths and
                provide recommendations to overcome obstacles. We optimize
                solutions to offer valuable insights on customer journey,
                purchase history, and cart abandonment data.
              </p>
            </div>
          </div>
          <div className="sub_card_pro_eng_botm" style={{ height: '70%' }}>
            <div className="sub_card_pro_eng_head">
              <img src={require("../Assets/card_sub2.png")} />
            </div>
            <div className="sub_card_pro_eng_footer">
              <center>
                <h5 className="sub_card_heading">Insightful</h5>
              </center>
              <p className="para_pro_card_eng">
                Unlock growth potential through strategic consulting in
                eCommerce. Drive traffic, increase conversions, and optimize
                sales while streamlining operational processes. Leverage our
                expertise in marketing, technology, operations.
              </p>
            </div>
          </div>

          <div className="sub_card_pro_eng_botm" style={{ height: '70%' }}>
            <div className="sub_card_pro_eng_head">
              <img src={require("../Assets/crad_sub3.png")} />
            </div>
            <div className="sub_card_pro_eng_footer">
              <center>
                <h5 className="sub_card_heading">Analytics</h5>
              </center>
              <p className="para_pro_card_eng">
                Experience seamless data management for your eCommerce business.
                Our cutting-edge services handle data processing needs,
                including store catalog maintenance, product data uploads, and
                efficient data organization.
              </p>
            </div>
          </div>
          <div className="sub_card_pro_eng_botm" style={{ height: '70%' }}>
            <div className="sub_card_pro_eng_head">
              <img src={require("../Assets/crad_sub4.png")} />
            </div>
            <div className="sub_card_pro_eng_footer">
              <center>
                {" "}
                <h5 className="sub_card_heading">Ecommerce Audit</h5>
              </center>
              <p className="para_pro_card_eng">
                Our comprehensive eCommerce site auditing examines vital areas
                like SEO, content, performance, traffic, and conversion to
                uncover improvement opportunities and drive better outcomes.{" "}
              </p>
            </div>
          </div>
          <div className="sub_card_pro_eng_botm" style={{ height: '70%' }}>
            <div className="sub_card_pro_eng_head">
              <img src={require("../Assets/crad_sub5.png")} />
            </div>
            <div className="sub_card_pro_eng_footer">
              <center>
                <h5 className="sub_card_heading">Promotion</h5>
              </center>
              <p className="para_pro_card_eng">

                Leverage omnichannel marketing strategies infused with impactful
                insights to effectively engage potential customers and make
                data-driven decisions{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div style={{ marginTop: '40px' }} className="mobileimg">

        <img src={left2_ecom} style={{ width: "100%", height: "100%" }} />
      </div>
      <div className="bg_baneer_proemg" ></div>
      <Endtwo />
      <div style={{ marginTop: '200px' }}></div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default EcommerceService;

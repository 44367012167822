import React, { useEffect } from "react";
import "../Styles/Solution.css";
import img1sol from "../Assets/sol/Group.png";
import sol6 from "../Assets/sol6pic.png";
import "bootstrap/dist/css/bootstrap.css";
import img3 from "../Assets/images/client_connect_app_img.png";
import img4 from "../Assets/images/client_keyboard_app.png";
import { Link } from "react-router-dom";
import Flag from "./Flag";
import Some from "../Assets/notedownsome.png";
import Some2 from "../Assets/some2.png";
import Some3 from "../Assets/some3.png";
import Some4 from "../Assets/some4.png";
import Some5 from "../Assets/some5.png";
import Some6 from "../Assets/some6.png";
import NewFaq from "./NewFaq";
function Solution6() {

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="banner_Solution6">
        <div className="Solutionbanner1_section">
          <h2>
            Empowering Hospital and Clinic Management with AI-Powered Solutions
          </h2>
          <p>
            MedEase: AI-powered healthcare management system for streamlined
            operations and improved patient care.
          </p>
        </div>
      </div>
      <section className="main_body_soltions">
        <section className="section1_solution">
          <div className="section1_content">
            <h3 style={{ color: "#3D4DAE" }}>
              CRM(Customer Relationship Management)
            </h3>
            <p style={{ color: "#686868", fontSize: "14px" }}>
              ClientConnect, developed by Meetintel ltd, is a
              state-of-the-art CRM (Customer Relationship Management) software
              integrated with advanced AI features. It is designed to empower
              businesses with efficient customer management and engagement
              capabilities, enabling them to stay competitive and relevant in
              today's dynamic business environment. ClientConnect offers a
              comprehensive suite of tools and functionalities to streamline
              customer interactions, optimize sales processes, and drive
              business growth.
            </p>
          </div>
          <div className="section2_img">
            <img src={sol6} className="imgsizing_sol2" />
          </div>
        </section>
        <section className="section2_solution">
          <h2
            style={{
              color: "#3D4DAE",
              marginBottom: "40px",
              fontWeight: "bolder",
            }}
          >
            Features MedEase
          </h2>
          <div className="card_container_solu1">
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intelligent Customer Engagement
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Utilize AI algorithms to gain insights into customer behavior,
                preferences, and needs. Leverage chatbots and virtual assistants
                for personalized and round-the-clock customer support.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Enhanced Sales Automation
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Automate sales processes with intelligent lead scoring,
                prioritization, and predictive analytics. Identify cross-selling
                and upselling opportunities for increased revenue generation.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Advanced Data Analytics
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Extract actionable insights from customer data with AI-driven
                analytics. Track key performance indicators, monitor customer
                trends, and make informed business decisions.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Seamless Integration
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                }}
              >
                Integrate with existing systems and platforms, ensuring a
                unified view of customer data across touchpoints.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Workflow Automation
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Streamline and automate routine tasks to improve team
                productivity and handle customer inquiries efficiently.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Scalability and Customizability
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Tailor the solution to meet the specific requirements of
                businesses of all sizes. Customize workflows, data fields, and
                user interfaces.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Data Security and Compliance
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Adhere to industry standards and implement robust security
                measures to protect sensitive customer information and ensure
                compliance with data protection regulations.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intuitive User Experience
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                User-friendly interface with interactive dashboards for easy
                navigation and task management. Quick onboarding and intuitive
                learning curve.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Meetintel ltd to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Data Security and Compliance
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Adhere to industry standards and implement robust security
                measures to protect sensitive customer information and ensure
                compliance with data protection regulations.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intuitive User Experience
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                User-friendly interface with interactive dashboards for easy
                navigation and task management. Quick onboarding and intuitive
                learning curve.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Meetintel ltd to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="btn_sol1">
              <Link to={"/Contact"}>
                <button className="btn_sub_col1">Request A Demo</button>
              </Link>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{
          backgroundColor: "#F5F5F5",
          paddingTop: "0px",
          paddingBottom: "30px",
        }}
        className="sixcard4"
      >
        <div className="container ppp" style={{ maxWidth: "1000px" }}>
          <h3
            style={{
              color: "#3D4DAE",
              fontSize: "48px",
              fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              marginLeft: "20px",
              paddingTop: "60px",
              marginBottom: "40px",
            }}
          >
            Benefits of AgroGenius
          </h3>

          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some} style={{ marginBottom: "15px" }} />
                <h4>Improved Operational Efficiency</h4>
                <p>
                  MedEase streamlines administrative processes, automates tasks,
                  and optimizes resource utilization, resulting in improved
                  operational efficiency for healthcare facilities
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some2} style={{ marginBottom: "15px" }} />
                <h4>Enhanced Patient Experience</h4>
                <p>
                  MedEase provides convenient appointment scheduling, quick
                  access to medical records, personalized health
                  recommendations, and efficient communication, enhancing the
                  overall patient experience
                </p>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some6} style={{ marginBottom: "15px" }} />
                <h4>Accurate Diagnosis and Treatment</h4>
                <p>
                  MedEase's AI-powered diagnosis support improves diagnostic
                  accuracy, leading to timely and effective treatment plans
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some3} style={{ marginBottom: "15px" }} />
                <h4>Cost Savings</h4>
                <p>
                  MedEase's AI features automate tasks, reduce errors, optimize
                  billing processes, and improve inventory management, resulting
                  in cost savings for healthcare facilities
                </p>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some4} style={{ marginBottom: "15px" }} />
                <h4>SData-Driven Decision Making</h4>
                <p>
                  MedEase's predictive analytics and reporting features provide
                  valuable insights for data-driven decision-making, optimizing
                  resource allocation and improving patient outcomes
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some5} style={{ marginBottom: "15px" }} />
                <h4>Enhanced Data Security</h4>
                <p>
                  MedEase incorporates robust data security measures to protect
                  patient information, ensuring compliance with privacy
                  regulations and maintaining data integrity
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className=" d-none d-sm-block ">
        <div className="empowering_business mt-5 ">
          <div className="text-center">
            <h3 className="text-askari-primary fw-bold">
              Empowering Businesses with AI-Powered CRM Capabilities
            </h3>
            <p className="mt-3" style={{ color: "white" }}>
              ClientConnect from Meetintel ltd empowers businesses across
              industries with its AI-powered CRM capabilities. By providing
              personalized customer engagement, automating sales processes,
              offering advanced data analytics, and ensuring seamless
              integration, ClientConnect helps businesses unlock growth and
              success in their respective industries.
            </p>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
}

export default Solution6;

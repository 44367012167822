import React from 'react'
import left2_ecom from '../Assets/mask_ecom.png'

function Endtwo() {
  return (
    <section className="end_pro" style={{ marginTop: "10.2%", marginBottom: "10.2%" }}>
      <div className="end_pro_left">
        <img src={left2_ecom} style={{ width: "100%", height: "100%" }} />
      </div>
      <div className="end_pro_right">
        <div className="right_pro_end">
          <h3 style={{ color: "#3D4DAE", fontWeight: "bolder" }}>
            Meetintel  Technology - Your Trusted Product Engineering Services Company
          </h3>
          <p>We have a proven track record of helping companies from different verticals navigate digital platforms. We can help you, too, with our engineering IT services.</p>
          <ul style={{ listStyle: 'outside' }}>
            <li>Product Experts</li>
            <li>On-demand Scalability</li>
            <li>Flexible Engagement Models</li>
            <li>Cost-Effective Solution</li>
            <li>On-time Delivery</li>
            <li>Agile Methodology</li>
            <li>Code Authorization</li>
            <li>Streamlined Management</li>
            <li>100% Customer Satisfaction</li>
            <li>24*7 Support and Maintenance</li>


          </ul>


        </div>
      </div>



    </section>
  )
}

export default Endtwo

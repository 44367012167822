import React from "react";
import "./Hearder.css";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import Software from "./Assets/Frame.png";
import InterLogo from "./Assets/ecomlogo.png";
import Two from "./Assets/two.png";
import Three from "./Assets/three.png";
import Four from "./Assets/four.png";
import Five from "./Assets/five.png";
import Six from "./Assets/six.png";
import Seven from "./Assets/seven.png";
import Eight from "./Assets/eight.png";
import Nine from "./Assets/nine.png";
import Ten from "./Assets/ten.png";
import Webdev from "./Assets/webdev.png";
import Appdev from "./Assets/appdev.png";
import Interprise from "./Assets/interprise.png";
import Software1 from "./Assets/Frame.png";
import Contant from "./Assets/contant.png";
import Consultant from "./Assets/consultant.png";
import Social from "./Assets/social.png";
import Automation from "./Assets/automation.png";
import Creative from "./Assets/creative.png";
import AIML from "./Assets/aiml.png";

var Whatwedo = () => {
  var abbass1 = () => {
    const butt1 = document.getElementById("first11");
    butt1.style.display = "block";
    const butt21 = document.getElementById("second11");
    butt21.style.display = "none";
    const butt31 = document.getElementById("third11");
    butt31.style.display = "none";
    const butt41 = document.getElementById("fourth11");
    butt41.style.display = "none";
    const butt51 = document.getElementById("fifth11");
    butt51.style.display = "none";
    const butt12 = document.getElementById("six11");
    butt12.style.display = "none";
    const butt212 = document.getElementById("seven11");
    butt212.style.display = "none";
    const butt312 = document.getElementById("eight11");
    butt312.style.display = "none";
    const butt412 = document.getElementById("nine11");
    butt412.style.display = "none";
    const butt512 = document.getElementById("ten11");
    butt512.style.display = "none";
  };
  var abbass2 = () => {
    const butt1 = document.getElementById("first11");
    butt1.style.display = "none";
    const butt21 = document.getElementById("second11");
    butt21.style.display = "block";
    const butt31 = document.getElementById("third11");
    butt31.style.display = "none";
    const butt41 = document.getElementById("fourth11");
    butt41.style.display = "none";
    const butt51 = document.getElementById("fifth11");
    butt51.style.display = "none";
    const butt12 = document.getElementById("six11");
    butt12.style.display = "none";
    const butt212 = document.getElementById("seven11");
    butt212.style.display = "none";
    const butt312 = document.getElementById("eight11");
    butt312.style.display = "none";
    const butt412 = document.getElementById("nine11");
    butt412.style.display = "none";
    const butt512 = document.getElementById("ten11");
    butt512.style.display = "none";
  };
  var abbass3 = () => {
    const butt1 = document.getElementById("first11");
    butt1.style.display = "none";
    const butt21 = document.getElementById("second11");
    butt21.style.display = "none";
    const butt31 = document.getElementById("third11");
    butt31.style.display = "block";
    const butt41 = document.getElementById("fourth11");
    butt41.style.display = "none";
    const butt51 = document.getElementById("fifth11");
    butt51.style.display = "none";
    const butt12 = document.getElementById("six11");
    butt12.style.display = "none";
    const butt212 = document.getElementById("seven11");
    butt212.style.display = "none";
    const butt312 = document.getElementById("eight11");
    butt312.style.display = "none";
    const butt412 = document.getElementById("nine11");
    butt412.style.display = "none";
    const butt512 = document.getElementById("ten11");
    butt512.style.display = "none";
  };
  var abbass4 = () => {
    const butt1 = document.getElementById("first11");
    butt1.style.display = "none";
    const butt21 = document.getElementById("second11");
    butt21.style.display = "none";
    const butt31 = document.getElementById("third11");
    butt31.style.display = "none";
    const butt41 = document.getElementById("fourth11");
    butt41.style.display = "block";
    const butt51 = document.getElementById("fifth11");
    butt51.style.display = "none";
    const butt12 = document.getElementById("six11");
    butt12.style.display = "none";
    const butt212 = document.getElementById("seven11");
    butt212.style.display = "none";
    const butt312 = document.getElementById("eight11");
    butt312.style.display = "none";
    const butt412 = document.getElementById("nine11");
    butt412.style.display = "none";
    const butt512 = document.getElementById("ten11");
    butt512.style.display = "none";
  };
  var abbass5 = () => {
    const butt1 = document.getElementById("first11");
    butt1.style.display = "none";
    const butt21 = document.getElementById("second11");
    butt21.style.display = "none";
    const butt31 = document.getElementById("third11");
    butt31.style.display = "none";
    const butt41 = document.getElementById("fourth11");
    butt41.style.display = "none";
    const butt51 = document.getElementById("fifth11");
    butt51.style.display = "block";
    const butt12 = document.getElementById("six11");
    butt12.style.display = "none";
    const butt212 = document.getElementById("seven11");
    butt212.style.display = "none";
    const butt312 = document.getElementById("eight11");
    butt312.style.display = "none";
    const butt412 = document.getElementById("nine11");
    butt412.style.display = "none";
    const butt512 = document.getElementById("ten11");
    butt512.style.display = "none";
  };
  var abbass6 = () => {
    const butt1 = document.getElementById("first11");
    butt1.style.display = "none";
    const butt21 = document.getElementById("second11");
    butt21.style.display = "none";
    const butt31 = document.getElementById("third11");
    butt31.style.display = "none";
    const butt41 = document.getElementById("fourth11");
    butt41.style.display = "none";
    const butt51 = document.getElementById("fifth11");
    butt51.style.display = "none";
    const butt12 = document.getElementById("six11");
    butt12.style.display = "block";
    const butt212 = document.getElementById("seven11");
    butt212.style.display = "none";
    const butt312 = document.getElementById("eight11");
    butt312.style.display = "none";
    const butt412 = document.getElementById("nine11");
    butt412.style.display = "none";
    const butt512 = document.getElementById("ten11");
    butt512.style.display = "none";
  };
  var abbass7 = () => {
    const butt1 = document.getElementById("first11");
    butt1.style.display = "none";
    const butt21 = document.getElementById("second11");
    butt21.style.display = "none";
    const butt31 = document.getElementById("third11");
    butt31.style.display = "none";
    const butt41 = document.getElementById("fourth11");
    butt41.style.display = "none";
    const butt51 = document.getElementById("fifth11");
    butt51.style.display = "none";
    const butt12 = document.getElementById("six11");
    butt12.style.display = "none";
    const butt212 = document.getElementById("seven11");
    butt212.style.display = "block";
    const butt312 = document.getElementById("eight11");
    butt312.style.display = "none";
    const butt412 = document.getElementById("nine11");
    butt412.style.display = "none";
    const butt512 = document.getElementById("ten11");
    butt512.style.display = "none";
  };
  var abbass8 = () => {
    const butt1 = document.getElementById("first11");
    butt1.style.display = "none";
    const butt21 = document.getElementById("second11");
    butt21.style.display = "none";
    const butt31 = document.getElementById("third11");
    butt31.style.display = "none";
    const butt41 = document.getElementById("fourth11");
    butt41.style.display = "none";
    const butt51 = document.getElementById("fifth11");
    butt51.style.display = "none";
    const butt12 = document.getElementById("six11");
    butt12.style.display = "none";
    const butt212 = document.getElementById("seven11");
    butt212.style.display = "none";
    const butt312 = document.getElementById("eight11");
    butt312.style.display = "block";
    const butt412 = document.getElementById("nine11");
    butt412.style.display = "none";
    const butt512 = document.getElementById("ten11");
    butt512.style.display = "none";
  };
  var abbass9 = () => {
    const butt1 = document.getElementById("first11");
    butt1.style.display = "none";
    const butt21 = document.getElementById("second11");
    butt21.style.display = "none";
    const butt31 = document.getElementById("third11");
    butt31.style.display = "none";
    const butt41 = document.getElementById("fourth11");
    butt41.style.display = "none";
    const butt51 = document.getElementById("fifth11");
    butt51.style.display = "none";
    const butt12 = document.getElementById("six11");
    butt12.style.display = "none";
    const butt212 = document.getElementById("seven11");
    butt212.style.display = "none";
    const butt312 = document.getElementById("eight11");
    butt312.style.display = "none";
    const butt412 = document.getElementById("nine11");
    butt412.style.display = "block";
    const butt512 = document.getElementById("ten11");
    butt512.style.display = "none";
  };
  var abbass10 = () => {
    const butt1 = document.getElementById("first11");
    butt1.style.display = "none";
    const butt21 = document.getElementById("second11");
    butt21.style.display = "none";
    const butt31 = document.getElementById("third11");
    butt31.style.display = "none";
    const butt41 = document.getElementById("fourth11");
    butt41.style.display = "none";
    const butt51 = document.getElementById("fifth11");
    butt51.style.display = "none";
    const butt12 = document.getElementById("six11");
    butt12.style.display = "none";
    const butt212 = document.getElementById("seven11");
    butt212.style.display = "none";
    const butt312 = document.getElementById("eight11");
    butt312.style.display = "none";
    const butt412 = document.getElementById("nine11");
    butt412.style.display = "none";
    const butt512 = document.getElementById("ten11");
    butt512.style.display = "block";
  };


  return (
    <>
      <div className="container-fluid webwhat" style={{ textAlign: "center" }}>
        <h2 className="whatwedo">What We Do</h2>
        <p className="parawhat">
          Expertise, thorough knowledge and firsthand experience along with
          strong Industry specific research skills help us in analyzing,
          <br />
          designing and implementing world class solutions.
        </p>
        <div className="container">
          <div className="row">
            <div className="col" id="first11">
              <div className="container webgrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{ color: "#EC9B00" }}>
                    Website Development
                  </h3>
                  <p>
                    Web development services are the core of our business. We
                    provide a wide range of professional services in the
                    development of web 2.0 and intranet applications of any
                    complexity. Our highly talented team endeavors to develop
                    web applications that can become an invaluable asset for
                    your company.
                  </p>
                </div>
                <div className="row bg-bl">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Custom Web Design & Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Web Apps</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Enterprise Portal Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Application Maintenance</li>
                      </div>
                    </ul>
                    <button
                      style={{
                        height: "40px",
                        width: "160px",
                        border: "none",
                        backgroundColor: "#EC9B00",
                        borderRadius: "5px",
                        color: "white",
                        float: "left",
                        margin: "20px",
                      }}
                    >
                      Explore More
                    </button>
                  </div>
                  <div className="col">
                    <img src={Webdev} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="second11">
              <div className="container appgrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{ color: "#6471D7" }}>
                    Application Development
                  </h3>
                  <p>
                    Develop, test, and deploy custom mobile apps using a single
                    code for all devices, browsers, and operating systems. At
                    Meetintel  Tech, we provide the tools and technologies that
                    enterprises need to demystify and accelerate mobile app
                    development.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Enterprise App Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile UI/UX Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile POS Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Maintenance and Support</li>
                      </div>
                    </ul>
                    <button
                      style={{
                        height: "40px",
                        width: "160px",
                        border: "none",
                        backgroundColor: "#6471D7",
                        borderRadius: "5px",
                        color: "white",
                        float: "left",
                        margin: "20px",
                      }}
                    >
                      Explore More
                    </button>
                  </div>
                  <div className="col">
                    <img src={Appdev} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="third11">
              <div className="container entergrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{ color: "#BF9752" }}>
                    Enterprise
                  </h3>
                  <p>
                    Create enterprise-level applications customized to your
                    business’ complex challenges and requirements with
                    high-performing cloud platforms. Alleviate common pain
                    points and let users complete their tasks efficiently and
                    easily without interdependency. Join hands with us to
                    leverage speed, scalability, and security!
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Custom Enterprise Application Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Enterprise Mobility Services</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Digital Transformation Services</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Software Integration</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Legacy Application Integration</li>
                      </div>
                    </ul>
                    <button
                      style={{
                        height: "40px",
                        width: "160px",
                        border: "none",
                        backgroundColor: "#BF9752",
                        borderRadius: "5px",
                        color: "white",
                        float: "left",
                        margin: "20px",
                      }}
                    >
                      Explore More
                    </button>
                  </div>
                  <div className="col">
                    <img src={Interprise} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="fourth11">
              {" "}
              <div className="container softwaregrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{ color: "#6471D7" }}>
                    Software Development
                  </h3>
                  <p>
                    At Meetintel ltd, we specialize in offering a
                    comprehensive suite of top-notch software development
                    services tailored to meet the unique needs of your business.
                    Our experienced team of software engineers is dedicated to
                    delivering cutting-edge solutions that will elevate your
                    company to new heights.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Custom Software Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Enterprise Software Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Application Maintenance & Support</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Quality Assurance & Testing</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Legacy Software Modernization</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Development</li>
                      </div>
                    </ul>
                    <button
                      style={{
                        height: "40px",
                        width: "160px",
                        border: "none",
                        backgroundColor: "#6471D7",
                        borderRadius: "5px",
                        color: "white",
                        float: "left",
                        margin: "20px",
                      }}
                    >
                      Explore More
                    </button>
                  </div>
                  <div className="col">
                    <img src={Software1} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="fifth11">
              <div className="container contantgrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{ color: "#20AF92" }}>
                    Content Management
                  </h3>
                  <p>
                    Take control of your customers across all channels and
                    touchpoints with hyper-accelerated digitally enhanced
                    customer relationships. Implement, optimize, and scale with
                    an undisputed CRM system!
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>CRM Implementation</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Workflow and Automation</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Entity Customization</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>CRM Migration and Support</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Request Resolution</li>
                      </div>
                    </ul>
                    <button
                      style={{
                        height: "40px",
                        width: "160px",
                        border: "none",
                        backgroundColor: "#20AF92",
                        borderRadius: "5px",
                        color: "white",
                        float: "left",
                        margin: "20px",
                      }}
                    >
                      Explore More
                    </button>
                  </div>
                  <div className="col">
                    <img src={Contant} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="six11">
              <div className="container creativegrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{ color: "#4C7CE0" }}>
                    Creative
                  </h3>
                  <p>
                    At Meetintel ltd, creativity is at the heart of what
                    we do. Our exceptional creative services are tailored to
                    make your brand stand out in a competitive market. Our team
                    of talented designers and creative minds are passionate
                    about turning ideas into captivating realities.{" "}
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Graphic Design</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Branding & Identity</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Logo Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>User Interface (UI) Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>User Experience (UX) Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Animation & Motion Graphics</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Video Production</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Illustration</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Print Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Social Media Graphics</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Packaging Design</li>
                      </div>
                    </ul>
                    <button
                      style={{
                        height: "40px",
                        width: "160px",
                        border: "none",
                        backgroundColor: "#4C7CE0",
                        borderRadius: "5px",
                        color: "white",
                        float: "left",
                        margin: "20px",
                      }}
                    >
                      Explore More
                    </button>
                  </div>
                  <div className="col">
                    <img src={Creative} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="seven11">
              <div className="container socialgrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{ color: "#C86485" }}>
                    Social Media Services
                  </h3>
                  <p>
                    Unlock the true potential of your brand's online presence
                    with our cutting-edge social media services. At Meetintel
                    Technologies, we understand the power of social media in
                    shaping brand identities, driving engagement, and fostering
                    customer loyalty. Our dedicated team of social media experts
                    is well-versed in crafting innovative strategies tailored to
                    your brand's unique needs and objectives.{" "}
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Social Media Strategy</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Content Creation</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Community Management</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Social Media Advertising</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Influencer Marketing</li>
                      </div>
                    </ul>
                    <button
                      style={{
                        height: "40px",
                        width: "160px",
                        border: "none",
                        backgroundColor: "#C86485",
                        borderRadius: "5px",
                        color: "white",
                        float: "left",
                        margin: "20px",
                      }}
                    >
                      Explore More
                    </button>
                  </div>
                  <div className="col">
                    <img src={Social} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col  hideOptions" id="eight11">
              <div className="container autograd">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{ color: "#FE776B" }}>
                    Automation Services
                  </h3>
                  <p>
                    At Meetintel ltd, we harness the power of automation
                    to transform businesses, homes, and industries, making them
                    more efficient, intelligent, and sustainable. Embrace the
                    future with our advanced automation services tailored to
                    meet your specific needs.{" "}
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Software Automation</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Hardware Automation</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>IoT Solutions</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Sensor Integration</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Home Automation</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Robotics and AI</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cloud Automation</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Industrial Automation</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Manufacturing Automation</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Smart Energy Management</li>
                      </div>
                    </ul>
                    <button
                      style={{
                        height: "40px",
                        width: "160px",
                        border: "none",
                        backgroundColor: "#FE776B",
                        borderRadius: "5px",
                        color: "white",
                        float: "left",
                        margin: "20px",
                      }}
                    >
                      Explore More
                    </button>
                  </div>
                  <div className="col">
                    <img src={Automation} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="nine11">
              <div className="container aigrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{ color: "#58B5D9" }}>
                    AI/ML Services
                  </h3>
                  <p>
                    Give your customers the best of humans and technology with
                    AI and ML solutions. Leverage the power of sentiment
                    analysis, predictive models, IoT-enabled systems, etc., to
                    prevent risks, automate workflows, measure performance, and
                    build better customer relations.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Custom Chatbot Development and Integration</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Social Media Sentiment Analysis</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Pattern Mining and Forecasts</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>AI-Powered Alarms</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Asset Management</li>
                      </div>
                    </ul>
                    <button
                      style={{
                        height: "40px",
                        width: "160px",
                        border: "none",
                        backgroundColor: "#58B5D9",
                        borderRadius: "5px",
                        color: "white",
                        float: "left",
                        margin: "20px",
                      }}
                    >
                      Explore More
                    </button>
                  </div>
                  <div className="col">
                    <img src={AIML} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="ten11">
              <div className="container colgrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{ color: "#277796" }}>
                    Consulting Services
                  </h3>
                  <p>
                    Welcome to Meetintel ltd, where we offer comprehensive
                    consulting services tailored to meet the diverse needs of
                    businesses worldwide. With expertise in IT Consulting and HR
                    Consultancy, we empower organizations to thrive and achieve
                    sustainable growth.{" "}
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>IT Consulting</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Data Science and Analytics Consulting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Digital Marketing Consulting</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Healthcare IT Consulting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Education Technology Consulting</li>
                      </div>
                    </ul>
                    <button
                      style={{
                        height: "40px",
                        width: "160px",
                        border: "none",
                        backgroundColor: "#277796",
                        borderRadius: "5px",
                        color: "white",
                        float: "left",
                        margin: "20px",
                      }}
                    >
                      Explore More
                    </button>
                  </div>
                  <div className="col">
                    <img src={Consultant} className="navimg" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-sm-12 ">
              <div className="overflow">
                <div className="whatwedoBtn  " onClick={abbass1}>
                  <img src={InterLogo} className="logowhat" />
                  <h4 className="orange">Website Development</h4>
                </div>
                <div className="whatwedoBtn1" onClick={abbass2}>
                  <img src={Two} className="logowhat" />
                  <h4 className="orange">App Development</h4>
                </div>
                <div className="whatwedoBtn2" onClick={abbass3}>
                  <img src={Three} className="logowhat" />
                  <h4 className="orange">Enterprise</h4>
                </div>
                <div className="whatwedoBtn3" onClick={abbass4}>
                  <img src={Four} className="logowhat" />
                  <h4 className="orange  mobileres">Software Development</h4>
                </div>
                <div className="whatwedoBtn4" onClick={abbass5}>
                  <img src={Five} className="logowhat" />
                  <h4 className="orange mobileres">Content Management</h4>
                </div>
                <div className="whatwedoBtn5" onClick={abbass6}>
                  <img src={Six} className="logowhat" />
                  <h4 className="orange">Creative</h4>
                </div>
                <div className="whatwedoBtn6" onClick={abbass7}>
                  <img src={Seven} className="logowhat" />
                  <h4 className="orange">Social Media Services</h4>
                </div>
                <div className="whatwedoBtn7" onClick={abbass8}>
                  <img src={Eight} className="logowhat" />
                  <h4 className="orange">Automation Services</h4>
                </div>
                <div className="whatwedoBtn8" onClick={abbass9}>
                  <img src={Nine} className="logowhat" />
                  <h4 className="orange">AI/ML Services</h4>
                </div>
                <div className="whatwedoBtn9" onClick={abbass10}>
                  <img src={Ten} className="logowhat" />
                  <h4 className="orange">Consulting Services</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Whatwedo;

import logo from "./logo.svg";
import OurServicesImg from "./Assets/images/our_services.png";
import DvpCardHead1 from "./Assets/images/dvp-card-head-1.png";
import DvpCardHead2 from "./Assets/images/dvp-card-head-2.png";
import DvpCardHead3 from "./Assets/images/dvp-card-head-3.png";
import DvpCardHead4 from "./Assets/images/dvp-card-head-4.png";
import "./Home.css";
import as2 from "./Assets/images/as3.svg";
import as3 from "./Assets/images/as3.svg";
// import as4 from './Assets/Assets/images/as4.svg';
import as5 from "./Assets/images/as5.svg";
import as6 from "./Assets/images/as6.svg";
import as7 from "./Assets/images/as7.svg";
import as8 from "./Assets/images/as8.svg";
import as9 from "./Assets/images/as9.svg";
import as10 from "./Assets/images/as10.svg";
import as11 from "./Assets/images/as11.svg";
import as12 from "./Assets/images/as12.svg";
import as13 from "./Assets/images/as13.svg";
import webDevVector from "./Assets/images/web_dev_vector.png";
import PcWebIcon from "./Assets/images/pc_web_icon.png";
import MobileAppIcon from "./Assets/images/mobile_app_icon.png";
import EnterpriseServiceIcon from "./Assets/images/enterprise_icon.png";
import SoftwareDevIcon from "./Assets/images/software_dev_icon.png";
import ContentDevIcon from "./Assets/images/content_dev_icon.png";
import CreativeDevIcon from "./Assets/images/creative_dev_icon.png";
import SmDevIcon from "./Assets/images/sm_dev_icon.png";
import AutomationDevIcon from "./Assets/images/automation_dev_icon.png";
import MlDevIcon from "./Assets/images/ml_service_btn.png";
import AppDevVector from "./Assets/images/app_dev_vector.svg";
import EnterpriseDevVector from "./Assets/images/enterprise_dev_vector.svg";
import SoftwareDevVector from "./Assets/images/software_dev_vector.svg";
import ContentDevVector from "./Assets/images/content_dev_vector.svg";
import CreativeDevVector from "./Assets/images/creative_dev_vector.svg";
import SmDevVector from "./Assets/images/sm_dev_vector.png";
import BulbIconDevProcess from "./Assets/images/BulbIconDevProcess.png";
// import MapBgContactSection from "./Assets/images/map_bg.png";
import AskariTechnologyContactSectBg from "./Assets/images/askari_technology_contact_sect.png";
import HeroSectionBackground from "./Assets/images/hero-section-bg.png";
import MlServiceVector from "./Assets/images/ml-service-vector.avif";
import ConsultingServiceDevIcon from "./Assets/images/consulting_service_dev.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";
import { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Whatwedo from "./Whatwedo";
import Tabs from "./tabs";
import BasicTabs from "./tabs";
import Whattodomobile from "./Components/Whattodomobile";
import { Link } from "react-router-dom";
import NewFaq from "./Components/NewFaq";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Mobtabs from "./Components/MobTabs";
const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [counterOn, setCounterOne] = useState(false);
  const handleBeforeChange = (oldIndex, newIndex) => {
    setActiveIndex(newIndex);
  };

  const handleAfterChange = (currentIndex) => {
    setActiveIndex(currentIndex);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="hero">
        <div className="uperdiv" ></div>
        <div className="container p-3">
          <div className="row8">
            <div className="col-lg-6">
              <h1 style={{ fontWeight: "700", color: "white" }}>
                Empowering Brands
              </h1>
              <h1 style={{ fontWeight: "700", color: "white" }}>Since 2016</h1>
              <p className="mt-5 text-white">
                We decode every complex challenge with our technical expertise
                and provide you with everything you need to drive measurable
                results. Get custom software development, consultation,
                marketing plans, and all other tools to scale and simplify the
                way you connect, market, and sell to your customers!
              </p>

              <Link to={"/Contact"}>
                <button
                  style={{ padding: "7px 25px 7px" }}
                  className="btn btn-light talk mt-50"
                >
                  Talk to Experts
                </button>
              </Link>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </div>

      <div className="our_services mb-5">
        <div className="container ">
          <div className="row">
            <div className="col-lg-6 mt-5">
              <img
                src={OurServicesImg}
                alt="Our services"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mt-5 div3">
              <h3
                className="text-askari-primary1 fw-bold me-auto"
                style={{ paddingLeft: "15px", }}
              >
                COMMITTED TO PROVIDING
              </h3>
              <h3
                style={{ marginRight: "35px", padding: "15px" }}
                className="text-askari-primary1 fw-bold"
              >
                SUBSTANTIVE BUSINESS VALUE
              </h3>

              <p className="mt-5 text-dark" style={{ padding: "15px" }}>
                {" "}
                <strong>Meetintel   ltd</strong> is committed to providing
                substantive business value of each and every product developed
                and client engagement. We achieve this through a combination of
                expert Industry-Specific business analysis, strong technical
                skills, sound project management methods and traditional values.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div >
        <div className="whatweb ">
          <Whatwedo />
        </div>
        <div className="MobileNavigation">
          <Whattodomobile />
        </div>
      </div>
      <div
        className="mb-5 mt-5 project_delivered"
        style={{ paddingBottom: "40px" }}
      >
        <div className="container text-center">
          <div style={{ height: "50px" }}></div>
          <h1 className="fw-bold text-white">Over 300+ Projects Delivered</h1>
          <p className="text-white">
            Clients trust us for our efforts to improve business agility and
            reduce costs.
          </p>
          <div style={{ height: "50px" }}></div>

          <Slider
            slidesToShow={3}
            centerMode={true}
            centerPadding={"5px"}
            infinite={true}
            beforeChange={handleBeforeChange}
            afterChange={handleAfterChange}
            arrows={false}
            autoplay={true}
            useTransform={true}
            speed={150}
            responsive={[
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  centerMode: true,
                  centerPadding: "8%",
                },
              },
            ]}
          >
            <div style={{
              width: 300,

            }}>
              <div
                className="card me-2 project_delivered_details_card text-start"
                style={{ backgroundColor: "lightyellow", marginRight: "10px" }}
              >
                <h1
                  class="card-heading fw-bold"
                  style={{ marginLeft: "20px", marginTop: "20px" }}
                >
                  01
                </h1>
                <div style={{ height: "30px" }}></div>

                <h4 className="fw-bold" style={{ marginLeft: "20px" }}>
                  Growth Centered Teams
                </h4>
                <hr />
                <p class="cardp"
                  style={{
                    fontSize: 14,
                    backgroundColor: "transparent",
                    paddingLeft: "30px",

                  }}
                >

                  At Meetintel   ltd, our focus lies in achieving outcomes and expansion. Our choices revolve around objectives, emphasizing involvement, loyalty, and user experience.
                </p>
              </div>
            </div>

            <div style={{ width: 300 }}>
              <div
                className="card me-2 project_delivered_details_card text-start"
                style={{ backgroundColor: "lightyellow", marginRight: "30px" }}
              >
                <h1
                  class="card-heading fw-bold"
                  style={{ marginLeft: "20px", marginTop: "20px" }}
                >
                  02
                </h1>
                <div style={{ height: "30px" }}></div>

                <h4 className="fw-bold" style={{ marginLeft: "20px" }}>
                  Security and IP Protection
                </h4>
                <hr />
                <p class="cardp"
                  style={{
                    fontSize: 14,
                    backgroundColor: "transparent",
                    paddingLeft: "30px",
                  }}
                >

                  Maintaining ISO 27001 and 9000 certifications, we guarantee the highest level of security for your data and restrict sharing to your own team. Our established procedures manage development, testing, and deployment.
                </p>
              </div>
            </div>

            <div style={{ width: '100%' }}>
              <div
                className="card me-2 project_delivered_details_card text-start"
                style={{ backgroundColor: "lightyellow", marginRight: "30px" }}
              >
                <h1
                  class="card-heading fw-bold"
                  style={{ marginLeft: "20px", marginTop: "20px" }}
                >
                  03
                </h1>
                <div style={{ height: "30px" }}></div>

                <h4 className="fw-bold" style={{ marginLeft: "20px" }}>
                  No Cost Overruns
                </h4>
                <hr />
                <p class="cardp"
                  style={{
                    fontSize: 14,
                    backgroundColor: "transparent",
                    paddingLeft: "30px",
                  }}
                >
                  Through our Agile approach and regular weekly meetings, we align closely with your core team to prevent unnecessary revisions. This guarantees quality, transparency, and prevents cost overruns.
                </p>
              </div>
            </div>
            <div style={{ width: 400 }}>
              <div
                className="card me-2 project_delivered_details_card text-start"
                style={{ backgroundColor: "lightyellow", marginRight: "30px" }}
              >
                <h1
                  class="card-heading fw-bold"
                  style={{ marginLeft: "20px", marginTop: "20px" }}
                >
                  04
                </h1>
                <div style={{ height: "30px" }}></div>

                <h4 className="fw-bold" style={{ marginLeft: "20px" }}>
                  Application Security
                </h4>
                <hr />
                <p class="cardp"
                  style={{
                    fontSize: 12,
                    backgroundColor: "transparent",
                    paddingLeft: "30px",
                  }}
                >
                  Impeccable security holds the same significance as error-free development. We conduct comprehensive testing, including static, dynamic, interactive, and mobile app assessments, to guarantee the security of developed software and applications.
                </p>
              </div>
            </div>
            {/* <div style={{width: 400}}>
        <div className="card me-2 project_delivered_details_card text-start" style={{ backgroundColor: 'lightyellow' ,marginRight:'30px', margin:'20px'}}>
        <h1 class="card-heading fw-bold" style={{marginLeft:'20px'}}>04</h1>
        <div style={{height: '20px'}}></div>

          <h4 className='fw-bold' style={{margin:'20px' ,marginTop:'-10px'}}>OUR EXPERTISE</h4>
          <hr />
          <p style={{fontSize: 14 ,backgroundColor:'transparent',paddingLeft:'30px'}}>The Meetintel  Technologies team is a cohesive unit of highly skilled professionals who boast extensive expertise across diverse Business and Technology areas. Our commitment to delivering world-class service is exemplified through our meticulous selection of the finest technicians in the field, ensuring that our clients receive nothing short of exceptional support.</p>
        </div>
      </div> */}
          </Slider>
        </div>
      </div>

      <div className="our_development_process_section mt-5 mb-5">
        <div className="container2 text-center">
          <h1 className="text-askari-primary fw-bold text-black">
            Our Development Process
          </h1>
          <div style={{ height: "20px" }}></div>
          <div className="row2">
            <div className="card2  ">
              <div className="card requirment_analysis_card h-100 pad5">
                <div
                  style={{
                    borderRadius: "50%",
                    border: "10px solid #EC9B00",
                    width: "96.24px",
                    height: "96.24px",
                    padding: "15px",
                    alignSelf: "center",
                    margin: "-3rem 1rem 0rem",
                    background: "#FFFFFF",
                  }}
                  className="card-header"
                >
                  <img src={DvpCardHead1} alt="Requirement analysis" />
                </div>
                <div className="card-body" style={{}}>
                  <h6 style={{ color: "#EC9B00" }} className="fw-bold">
                    Requirement Analysis
                  </h6>
                  <p style={{ fontWeight: 350, fontSize: 16 }}>

                    Upon initiating contact, we engage in discussions with you to collect and assess your needs and anticipations.
                  </p>
                </div>
              </div>
            </div>
            <div className=" card2 ">
              <div className="card building_the_right_team_card h-100 pad5">
                <div className="card-body">
                  <div style={{ height: "35px" }}></div>
                  <h6 className="fw-bold" style={{ color: "#6471D7" }}>
                    Building the right team
                  </h6>
                  <p style={{ fontWeight: 350, fontSize: 16 }}>
                    When you reach out to us, we initiate discussions to gather and evaluate your requirements and expectations.
                  </p>
                </div>
                <div
                  style={{
                    borderRadius: "50%",
                    border: "10px solid #6471D7",
                    width: "96.24px",
                    height: "96.24px",
                    padding: "15px",
                    alignSelf: "center",
                    margin: "0rem 1rem -3rem",
                    background: "#FFFFFF",
                  }}
                  className="card-footer"
                >
                  <img src={DvpCardHead2} alt="Building the right team" />
                </div>
              </div>
            </div>
            <div className="card2  ">
              <div className="card planning_and_execution_card h-100 carcir1 pad5">
                <div
                  style={{
                    borderRadius: "50%",
                    border: "10px solid #20AF92",
                    width: "96.24px",
                    height: "96.24px",
                    padding: "15px",
                    alignSelf: "center",
                    margin: "-3rem 1rem 0rem",
                    background: "#FFFFFF",
                  }}
                  className="card-header"
                >
                  <img src={DvpCardHead3} alt="Planning and execution" />
                </div>
                <div className="card-body">
                  <h6 className="fw-bold" style={{ color: "#20AF92" }}>
                    Planning and execution
                  </h6>
                  <p style={{ fontWeight: 350, fontSize: 16 }}>

                    Achieving team-wide alignment through weekly meetings, standups, demonstrations, and status reports to preempt potential conflicts.
                  </p>
                </div>
              </div>
            </div>
            <div className="card2  ">
              <div className="card review_and_delivery_card h-100 carcir2 pad5">
                <div className="card-body">
                  <div style={{ height: "35px" }}></div>
                  <h6 style={{ color: "#F1323C" }} className="fw-bold">
                    Review and Delivery
                  </h6>
                  <p style={{ fontWeight: 350, fontSize: 16 }}>
                    Prior to every sprint release, we conduct quality checks and code reviews to ensure a secure, bug-free product delivery.
                  </p>
                </div>
                <div
                  style={{
                    borderRadius: "50%",
                    border: "10px solid #F1323C",
                    width: "96.24px",
                    height: "96.24px",
                    padding: "13px",
                    alignSelf: "center",
                    margin: "0rem 1rem -3rem",
                    background: "#FFFFFF",
                  }}
                  className="card-footer"
                >
                  <img
                    src={DvpCardHead4}
                    alt="Review and Delivery"
                    style={{ height: "55px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '-59px' }}></div>
      <ScrollTrigger
        onEnter={() => setCounterOne(true)}
        onExit={() => setCounterOne(false)}
      >
        <div className="our_expertise mt-5">
          <div className="container text-center mt-8">
            <div style={{ height: "50px" }}></div>
            <h1
              style={{
                fontWeight: 700,

              }}
              className="text-askari-primary conti text-black"
            >
              Continue To Grow With Businesses In The World
            </h1>
            <div className="row3 mt-5">
              <div className="col-md-4">
                <h1 className="fw-bold">
                  {counterOn && (
                    <CountUp start={0} end={10} duration={4} delay={0} />
                  )}
                  +
                </h1>
                <h3>Years Of Expertise</h3>
                <p>

                  Learning, evolving, and adapting – our ongoing path to success.
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="fw-bold">
                  {counterOn && (
                    <CountUp start={0} end={300} duration={2} delay={0} />
                  )}
                  +
                </h1>
                <h3>Projects Completed</h3>
                <p>

                  We're thankful to consider them all our satisfied customers!
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="fw-bold">
                  {counterOn && (
                    <CountUp start={0} end={50} duration={2} delay={0} />
                  )}
                  +
                </h1>
                <h3>Dynamic Individuals</h3>
                <p>
                  They love to innovate everyday and <br /> work for a common
                  goal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ScrollTrigger>

      <Mobtabs />
    </div>
  );
};

export default Home;

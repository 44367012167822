import React from "react";
import "../Styles/DevlopnerPage.css";
import mainAbout from "../Assets/shopdeveop2.png";
import Contactsub from "./Contactsub";
import { HashLink } from "react-router-hash-link";
import NewFaq from "./NewFaq";
import Flag from "./Flag";
import Hidemob from "../Assets/pix.png";
const MagnetoDevelopment = () => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <section className="Hero-Shopfy-section-1 All-hero-main Section  this-magento">
        <div className="container p-4 main-first-hero-all-content">
          <div className="row p-2">
            <div className="col-lg-6 side-heading-para-content">
              <h1 className="main-hero-h1 py-3">
                Magento Development Services
              </h1>
              <p className="main-hero-para-main-div">
                Transform and enrich eCommerce experiences for your customers
                worldwide with our custom Magento development services. Achieve
                enhanced growth and success for startups, SMEs, and enterprises.
              </p>
              <HashLink to={"#contact-us"} >
                <div className="btnnn py-4">

                  <button className="main-home-shopyt-btn ">
                    Get Started With Magento Development
                  </button>

                </div>    </HashLink>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </section>

      {/* New second Section */}

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Deliver Optimal Shopping Experiences With Our Magento Services
                </h2>
                <p className="about-main-content-parap">
                  Meetintel ltd, a leading Magento development company,
                  offers comprehensive custom Magento development services. With
                  over 16 years of experience, we have delivered diverse IT
                  solutions and services to our clients..<br></br> <br></br>
                  Magento Theme Development: Enhance the look and feel of your
                  store with custom Magento theme development, tailored to your
                  brand's needs and objectives. <br></br> <br></br>
                  Magento Extension Development: Extend the functionality of
                  your Magento store with custom Magento extensions, designed to
                  add more features and capabilities.<br></br> <br></br>
                  Magento PWA Development: Improve the performance and user
                  experience of your Magento store with Magento Progressive Web
                  App (PWA) development, offering a seamless mobile experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mobileimg">
          <img src={Hidemob} />
        </div>
      </section>

      <Contactsub />

      <section className="main-services-card-main-div p-5 ">
        <div className="contianer p-md-5" >
          <div className="row p-2">
            <h1 className="main-serv-ces-h1-head">
              Our Magento Development Approach
            </h1>

            <div className="col-md-3 col-sm-8 card-main-div-main-card set">
              <p className="card-top-main">Store Setup & Configuration:</p>
              <p className="main-top-bottom-content">
                Swiftly launch your online store with expert assistance in
                setting up and configuring your Magento store according to your
                objectives.
              </p>
            </div>

            <div className="col-md-3 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Magento Web Development</p>
              <p className="main-top-bottom-content">
                Create scalable and secure eCommerce platforms with
                industry-specific functionality, designed to help your business
                thrive.
              </p>
            </div>

            <div className="col-md-3 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Magento Custom Development:</p>
              <p className="main-top-bottom-content">
                Customize your Magento store to reflect your brand's unique
                identity and meet your specific requirements.
              </p>
            </div>

            <div className="col-md-3 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Magento Migration</p>
              <p className="main-top-bottom-content">
                Seamlessly transition your business from various platforms to
                Magento, ensuring a smooth and efficient migration process.
              </p>
            </div>

            <div className="col-md-3 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Magento Integration</p>
              <p className="main-top-bottom-content">
                Streamline business processes by connecting your Magento store
                with CRM, ERP, and other systems for improved efficiency.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid contt">
          <div className="container" style={{ textAlign: 'center' }}>
            <h1 className="arrivals-head-main-top-my-bottom" >
              What Makes Meetintel  a Leading Shopify Development Company
            </h1>
            <p className="main-services-para-main-card-top py-3" style={{ color: 'white' }}>
              We offer all-inclusive Shopify services that help add more
              functionality, monitor performance, improve sales channels &
              inventory management, and many more. Store Setup & Configuration
            </p>
            <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className="col" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Feature-Rich Stores</p>
                  <p className="arricval-card-content-main-last-top">
                    Our Magento development services create feature-rich stores that
                    offer enhanced functionality and a seamless shopping experience for your customers.
                    We integrate advanced features such as product catalogs, payment gateways, search
                    capabilities, and more to optimize your eCommerce operations.
                  </p>
                </div>
              </div>
              <div className="col" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Scalability and Flexibility</p>
                  <p className="arricval-card-content-main-last-top">
                    With Magento, we build scalable and flexible eCommerce
                    solutions that can grow with your business. Our developers ensure that your Magento
                    store can handle high volumes of traffic and accommodate future expansions without
                    compromising performance.
                  </p>
                </div>
              </div>
              <div className="col" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Increased Security</p>
                  <p className="arricval-card-content-main-last-top">
                    We prioritize the security of your Magento store and implement
                    robust security measures to protect sensitive customer data, prevent unauthorized
                    access, and safeguard against cyber threats. Our experts follow industry best practices
                    to ensure a secure eCommerce environment.
                  </p>
                </div>
              </div>
              <div className="col" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Custom Support and Maintenance</p>
                  <p className="arricval-card-content-main-last-top">
                    Our Magento support and maintenance services
                    ensure that your store remains up and running smoothly. We provide ongoing technical
                    assistance, regular updates, bug fixes, and performance optimizations to keep your
                    Magento store operating at its best.
                  </p>
                </div>
              </div>
            </div>

            <button className="buto" >Get Started With Magento Development</button>

          </div>

        </div>
      </section>


      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Why Choose Meetintel ltd for Magento Store Development
                </h2>

                <ul className="newul">
                  <li>Experience and Expertise</li>
                  <li>Comprehensive Solutions</li>

                  <li>Customization and Flexibility</li>
                  <li>Cutting-Edge Technology</li>
                  <li>Client-Centric Approach</li>
                  <li>Proven Results</li>
                  <li>Longoing Support</li>
                  <li>On-time Delivery</li>
                  <li>Client-Centric Approach</li>
                  <li>Proven Results</li>
                  <li>Longoing Support</li>
                  <li>On-time Delivery</li>
                </ul>
                <HashLink to={"#contact-us"}>
                  <button className="bolet-btn-main">
                    Request Consultation
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default MagnetoDevelopment;

import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/QualityEng.css";
import leftmain from "../Assets/leftmain.png";
import bg from "../Assets/bg.png";
import img1 from "../Assets/1.jpg";
import img2 from "../Assets/2.jpg";
import img3 from "../Assets/3.jpg";
import img4 from "../Assets/4.jpg";
import img5 from "../Assets/5.jpg";
import pic1 from "../Assets/pic1.png";
import pic2 from "../Assets/pic2.png";
import pic3 from "../Assets/pic3.png";
import pic4 from "../Assets/pic4.png";
import pic5 from "../Assets/pic5.png";
import autoparts from "../Assets/autoparts.png";
import pharma from "../Assets/pharma.png";
import apple from "../Assets/apple.png";
import beauty from "../Assets/beauty.png";
import car from "../Assets/car.png";
import coding from "../Assets/coding.png";
import check from "../Assets/check.png";
import bg2 from "../Assets/bg2.png";
import office from "../Assets/office.png";
import process from "../Assets/proces.png";
import js from "../Assets/js.png";
import php from "../Assets/php.png";
import microsoft from "../Assets/microsoft.png";
import salesforce from "../Assets/salesforce.png";
import odoo from "../Assets/odoo.png";
import netcore from "../Assets/netCore.png";
import askarimainleft from "../Assets/askaritechmain.png";
import aus from "../Assets/aus.png";
import ame from "../Assets/ame.png";
import pak from "../Assets/pak.png";
import uae from "../Assets/uae.png";
import Planning from "../Assets/11.png";
import Design from "../Assets/22.png";
import Stable from "../Assets/44.png";
import Implement from "../Assets/33.png";
import { HashLink } from "react-router-hash-link";
import Technologywework from "./Technologywework";
import { Link } from "react-router-dom";
import Flag from "./Flag";
import NewFaq from "./NewFaq";
import Step from "../Assets/newstep.png";
const QualityEng = () => {
  return (
    <div className="bodycontent" style={{ overflow: "hidden" }}>
      <div className="bannner-image-qua">
        {/* <p className="text-center text-white ">
         
        </p> */}

        <div className="">
          <h1 style={{ fontWeight: "bolder", marginTop: "3%" }}>
            Your Products <br />
          </h1>
          <h1 style={{ fontWeight: "bolder", marginTop: "3%" }}>
            Our Assurance
          </h1>
          <br></br>
          <p
            style={{
              fontSize: "13px",
              marginBottom: "7%",
              marginTop: "-20px",
            }}
          >
            Delivering quality products with Product Engineering services.
          </p>
          <Link to="/Contact">
            <button className="btn_pro" style={{ marginTop: "70px" }}>
              Request Consultation{" "}
            </button>
          </Link>
        </div>
      </div>
      <div className="row mainsection">
        <div className="col">
          {/* <div className="image-container">
            <img src={bg} className="bg-image" alt="JPEG Image" />
          </div> */}
        </div>
      </div>
      <div className="container">
        <div className="skilled44">
          <h1>Quality Engineering Services</h1>
        </div>
      </div>
      <div className="image-row">
        <div class="ui-card" style={{ margin: "10px" }}>
          <img src={img1} />
          <div class="description">
            <h3>Quality Engineering Strategy</h3>
            <p>
              Quality Engineering: Merging Checks, Assurance, Expertise for Tailored Excellence
            </p>
          </div>
        </div>
        <div class="ui-card" style={{ margin: "10px" }}>
          <img src={img2} />
          <div class="description">
            <h3>Quality Assurance</h3>
            <p>
              Robust Testing for Assured Quality. Enterprise-level Solutions Across Industries.
            </p>
          </div>
        </div>
        <div class="ui-card" style={{ margin: "10px" }}>
          <img src={img3} />
          <div class="description">
            <h3>Test Automation</h3>
            <p>
              Automated Testing for Flawless Products and Business Growth.
            </p>
          </div>
        </div>
        <div class="ui-card" style={{ margin: "10px" }}>
          <img src={img3} />
          <div class="description">
            <h3>Quality Mastery</h3>
            <p>
              Achieving perfection through precision.
            </p>
          </div>
        </div>
        <div class="ui-card" style={{ margin: "10px" }}>
          <img src={img1} />
          <div class="description">
            <h3>Engineering Excellence</h3>
            <p>
              Crafting excellence at its core.
            </p>
          </div>
        </div>
      </div>

      <div></div>
      <div className="container">
        <div className="row">
          <div className="skilled">
            <h3>Work with a Team of Skilled Professionals</h3>
            <p>
              Seamless collaboration, total transparency, and secure delivery.
              That’s our approach when crafting your application. A team with
              years of experience to suggest the best solution for you.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="image-section">
            <img src={process} alt="Your Image" />
          </div>
          <div className="Stepmob">
            <img src={Step} />
          </div>
        </div>
      </div>

      <Technologywework />
      {/* cards */}


      <div className="container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "50px", marginBottom: "50px" }}>
        <div className="row " style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div
            className="col-sm-12"
            style={{
              margin: "10px",
              height: "420px",
              width: "285px",
              backgroundColor: "#FCFCFC",
              border: "1px solid lightgrey",
              paddingTop: "2px",
              borderRadius: '10px',
              backgroundColor: '	#F5F5F5'

            }}
          >
            <div className="col">
              <div className="row">
                <img
                  src={require("../Assets/card22.png")}
                  style={{ height: "240px", width: "100%" }}
                />
              </div>
              <div className="row">
                {" "}
                <div className="content_qa_card" >
                  <h5>Stabilization / Delivery</h5>
                  <p>
                    Lastly, fixed bugs are rechecked for a bug-free project.
                    When required we perform regression testing based on
                    previous sprints. The last step is user acceptance testing
                    and if everything is okay.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-12"
            style={{
              margin: "10px",
              height: "420px",
              width: "285px",
              backgroundColor: "#FCFCFC",
              border: "1px solid lightgrey",
              paddingTop: "2px",
              borderRadius: '10px',
              backgroundColor: '	#F5F5F5'

            }}
          >
            <div className="col">
              <div className="row">
                <img
                  src={require("../Assets/crad23.png")}
                  style={{ height: "240px", width: "100%" }}
                />
              </div>
              <div className="row">
                {" "}
                <div className="content_qa_card" >
                  <h5>Stabilization / Delivery</h5>
                  <p>
                    Lastly, fixed bugs are rechecked for a bug-free project.
                    When required we perform regression testing based on
                    previous sprints. The last step is user acceptance testing
                    and if everything is okay.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-12"
            style={{
              margin: "10px",
              height: "420px",
              width: "285px",
              backgroundColor: "#FCFCFC",
              border: "1px solid lightgrey",
              paddingTop: "2px",
              borderRadius: '10px',
              backgroundColor: '	#F5F5F5'

            }}
          >
            <div className="col">
              <div className="row">
                <img
                  src={require("../Assets/crad24.png")}
                  style={{ height: "240px", width: "100%" }}
                />
              </div>
              <div className="row">
                {" "}
                <div className="content_qa_card" >
                  <h5>Stabilization / Delivery</h5>
                  <p>
                    Lastly, fixed bugs are rechecked for a bug-free project.
                    When required we perform regression testing based on
                    previous sprints. The last step is user acceptance testing
                    and if everything is okay.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-12"
            style={{
              margin: "10px",
              height: "420px",
              width: "285px",
              backgroundColor: "#FCFCFC",
              border: "1px solid lightgrey",
              paddingTop: "2px",
              borderRadius: '10px',
              backgroundColor: '	#F5F5F5'

            }}
          >
            <div className="col">
              <div className="row">
                <img
                  src={require("../Assets/crad25.png")}
                  style={{ height: "240px", width: "100%" }}
                />
              </div>
              <div className="row">
                {" "}
                <div className="content_qa_card" >
                  <h5>Stabilization / Delivery</h5>
                  <p>
                    Lastly, fixed bugs are rechecked for a bug-free project.
                    When required we perform regression testing based on
                    previous sprints. The last step is user acceptance testing
                    and if everything is okay.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="conhide">

        <div className="right-side" >
          <h3 style={{ fontSize: '28px' }}>Why Meetintel ltd</h3>
          <p>
            Our work ethic, methodology, and dedication to your projects
            make us the perfect choice.
          </p>
          <p>
            We understand your requirements, dig deep into how to tailor a
            solution for you, build a team to work with you, and execute a
            robust project.
          </p>
          <HashLink to="#contact-us">
            <button style={{ border: "none", width: '165px', height: '40px' }}>Request Consultation</button>
          </HashLink>

        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src={askarimainleft}
              alt="Your Image"
              className="leftImage"
              style={{ marginTop: '30px', marginBottom: '30px' }} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="askaritechnology main" style={{ marginTop: "-10px" }}>
          <div className="row">
            <div className="col askaritechnology ">
              <div className="left-side">
                <img
                  src={askarimainleft}
                  alt="Your Image"
                  className="leftImage"
                />
              </div>
            </div>
            <div className="col  askaritechnology ">
              <div className="right-side">
                <h3>Why Meetintel ltd</h3>
                <p>
                  Our work ethic, methodology, and dedication to your projects
                  make us the perfect choice.
                </p>
                <p>
                  We understand your requirements, dig deep into how to tailor a
                  solution for you, build a team to work with you, and execute a
                  robust project.
                </p>
                <Link to="/Contact">
                  <button style={{ border: "none" }}>
                    Request Consultation
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '10px' }}></div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default QualityEng;

import React from "react";
import "../Styles/Footer.css";
import {} from "react-router-dom";
import { Link } from "react-router-dom";
import { color } from "@chakra-ui/react";
import CallMadeIcon from '@mui/icons-material/CallMade';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const Footer = () => {
  return (
    <>
      <div className="body">
        <div className="row10">
          <Link to={"/"}>
            <img
              src={require("../Assets/meetintellogo2.png")}
              className="logo1"
              style={{ width: "370px", height: "190px", marginBottom: "5px" }}
            />
          </Link>
          <div className="row7 footerCon">
            <div className="col-md-3 col-sm-8">
              <Link
                to="/about"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/>About Us
              </Link>
              <br></br>
              <Link
                to="/patner"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                {" "}
                < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> Partners
              </Link>
              <br></br>
              <Link
                to="/ouventure"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                   < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> How It Work
              </Link>
              <br></br>
              <Link
                to="/contact"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                   < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> Contact Us
              </Link>
              <br></br>
              <Link
                to="/proeng"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                   < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> Software Engineering
              </Link>
              <br></br>
              <Link
                to="/patner"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/>  Partners
              </Link>
              <br></br>
              <Link
                to="/Quality"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                   < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> Quality Engineering
              </Link>
              <br></br>
              <Link
                to="/devops"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/>  Devops
              </Link>
              <br></br>
            </div>
            <div className="col-md-3 col-sm-9">
              <Link
                to="/Alml"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/>  AI 
              </Link>
              <br></br>
              <Link
                to="/shopifydevelopment"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                   < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> Shopfiy
              </Link>
              <br></br>
              <Link
                to="/magentodevelopment"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/>  Magento 
              </Link>
              <br></br>
              <Link
                to="/woocommerce"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/>  Woocommerce
              </Link>
              <br></br>
              <Link
                to="/Ecommerceapp"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'400'}}/>  E Commerce 
              </Link>
              <br></br>
              <Link
                to="/Dynamic365"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/>  Dynamic 365
              </Link>
              <br></br>
              <Link
                to="/odoo"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                   < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> odoo
              </Link>
              <br></br>
              <Link
                to="/salesconsulting"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/>  Consulting
              </Link>
              <br></br>
            </div>
            <div className="col-md-3 col-sm-8">
              <Link
                to="/sol1"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/>  Asan ERP
              </Link>
              <br></br>
              <Link
                to="/sol2"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> Congnitive Edu
              </Link>
              <br></br>
              <Link
                to="/sol3"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                   < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> Client Connect
              </Link>
              <br></br>
              <Link
                to="/sol5"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                   < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> Instant Sales
              </Link>
              <br></br>
              <Link
                to="/sol6"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                   < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> Meadease
              </Link>
              <br></br>
              <Link
                to="/sol4"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> Angrisense
              </Link>
              <br></br>
              <Link
                to="/salesmigration"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                   < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/> Migration
              </Link>
              <br></br>
              <Link
                to="/salesintregation"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                  < ArrowOutwardIcon style={{fontSize:'20px',fontWeight:'500'}}/>  Intregation
              </Link>
              <br></br>
            </div>
            <div className="col-md-3 footright">
              <Link to="" style={{ textDecoration: "none", color: "#fff" }}>
              Subscribe to our e-newsletter
              </Link>
          
              <p className="email">Email </p>

              <form
                target="_blank"
                action="https://formsubmit.co/sales@askaritechnologie.com"
                method="POST"
              >
                <input
                  type="text"
                  name="email"
                  placeholder="Type Email"
                  style={{
                    backgroundColor: "transparent",
                    borderBottom: "2px solid white",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    color: "white",
                  }}
                  className="out"
                />
                <br />
                <button
                  type="submit"
                  class="btn"
                  style={{
                    width: "120px",
                    border: "1px solid white",
                    marginTop: "12px",
                    marginBottom: "15px",
                  }}
                >
                  <p style={{ color: "white", marginBottom: "0px" }}>Sign Up</p>
                </button>
              </form>

              <div className="row5 social">
                <div className="col">
                <a href="https://www.facebook.com/people/Askari-Technologies/100092249940977/"> <img src={require("../Assets/facebook.png")} style={{height:'20px',width:'20px'}} /></a> 
                </div>
                <div className="col">
                <a href="">   <img src={require("../Assets/youtube.png")} /></a> 
                </div>
                <div className="col">
                <a href="">  <img src={require("../Assets/twitter.png")} /></a> 
                </div>
                <div className="col">
                <a href="">  <img src={require("../Assets/instagram.png")} /></a> 
                </div>
                <div className="col">
                    <a href="https://uk.linkedin.com/company/askaritechnologies" style={{color:'white'}}><LinkedInIcon/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/secdule.css";
import Form from "react-bootstrap/Form";
import Flag from "./Flag";
import NewFaq from "./NewFaq";
import { Link } from "react-router-dom";
import axios from "axios";

const Secdule = () => {
  const [Name, SetName] = useState("");
  const [Email, SetEmail] = useState("");
  const [cName, SetCName] = useState("");
  const [Message, SetMessage] = useState("");
  const [Number, SetNumber] = useState("");
  const [isFormValid, setIsFormValid] = useState(true); // Initially set to true

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update the state based on input changes
    if (name === "Name") {
      SetName(value);
    } else if (name === "Email") {
      SetEmail(value);
    } else if (name === "cName") {
      SetCName(value);
    } else if (name === "Message") {
      SetMessage(value);
    } else if (name === "Number") {
      SetNumber(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic
    if (Name && Email && cName && Message && Number) {
      setIsFormValid(true);

      // Create an object with the form data
      const formData = {
        name: Name,
        email: Email,
        companyName: cName,
        message: Message,
        number: Number,
      };

      // Send a POST request to your server
      axios
        .post("YOUR_API_ENDPOINT", formData)
        .then((response) => {
          // Handle success
          console.log("Form submitted successfully");
        })
        .catch((error) => {
          console.error("Error submitting form", error);
        });
    } else {
      setIsFormValid(false);
    }
  };

  const [num1, setNum1] = useState(Math.floor(Math.random() * 11));
  const [num2, setNum2] = useState(Math.floor(Math.random() * 11));
  const [userInput, setUserInput] = useState("");
  const [message, setMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleSubmit1 = () => {
    const sum = num1 + num2;

    if (parseInt(userInput) === sum) {
      setMessage("Correct");
      setIsButtonDisabled(false);
    } else {
      setMessage("Incorrect. Try again.");
      console.log("Incorrect. Try again");
      setIsButtonDisabled(true);
    }
  };

  return (
    <>
      <div className="container-fluid Hero-ection-banner-bg">
        <div className="container main-contenr">
          <h1 className="main-head-hero-section">Schedule A Demo</h1>
          <p className="new-para-main-hero-section">
            Revolutionize Your Workflow: Request a Personalized Software Demo
          </p>
        </div>
      </div>

      <div className="container form-new-01" style={{ marginBottom: "360px", padding: "30px" }}>
        <h2 className="from-head">Book A Demo</h2>
        <form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="labelInout">
              Name {Name ? <span></span> : <span>*</span>}
            </Form.Label>
            <Form.Control
              type="text"
              name="Name"
              placeholder="Enter Your Name"
              value={Name}
              onChange={handleInputChange}
            />
            {!Name && !isFormValid && <p className="Enter-Name">Please enter a name</p>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="labelInout">
              Email {Email ? <span></span> : <span>*</span>}
            </Form.Label>
            <Form.Control
              type="email"
              name="Email"
              placeholder="Enter Your Email"
              value={Email}
              onChange={handleInputChange}
            />
            {!Email && !isFormValid && <p className="Enter-Name">Please enter an email</p>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="labelInout">
              Company Name {cName ? <span></span> : <span>*</span>}
            </Form.Label>
            <Form.Control
              type="text"
              name="cName"
              placeholder="Enter Your Company Name"
              value={cName}
              onChange={handleInputChange}
            />
            {!cName && !isFormValid && <p className="Enter-Name">Please enter a company name</p>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="labelInout">
              Message {Message ? <span></span> : <span>*</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="Message"
              placeholder="Give us a brief about your requirements"
              value={Message}
              onChange={handleInputChange}
            />
            {!Message && !isFormValid && <p className="Enter-Name">Please enter a message</p>}
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div>
              <h1>Guess the Sum</h1>
              <p>Enter the sum of the following numbers:</p>
              <span>
                {num1} + {num2} = ?
              </span>
              <br />
              <input
                type="number"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                className="logicinp"
              />
              <button onClick={handleSubmit1} className="sublogic">
                Done
              </button>
              <p>{message}</p>
            </div>
          </Form.Group>
          <p className="form-accept-link">
            By submitting this form, you are agreeing to Meetintel ltd Privacy Policy and Terms of Service.
          </p>
          <Link to='/TimeSecdule'>
            <div className="submit-btn">

              {" "}
              <button type="submit" onClick={handleSubmit1} disabled={isFormValid || isButtonDisabled}>
                Submit
              </button>

            </div>
          </Link>
        </form>
      </div>
      <Flag />
      <NewFaq />
    </>
  );
};

export default Secdule;

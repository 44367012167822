import React from "react";
import "../Styles/Hearder.css";
import Logo from "../Assets/meetintellogo3.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import About from "./About";
import Capability from "./Capability";
import Insight from "./Insight";
import SideBar from "./SideBar";
import HireDevSidebar from "./HireDevSidebar";
import DevSidebar from "./DevSidebar";
import Solutions from "./Solutions";
import { Link } from "react-router-dom";
const NewHeader = () => {
  return (
    <>

<div>
  
</div>
      <header className="header viewnon" style={{display:'', justifyContent:'',alignItems:''}}>
        <div className="container1">
          <div className="row v-center ">
            <div className="header-item item-left">
              <div className="imagediv">
                <Link to="/" style={{textDecoration:'none',color:'transparent'}}><img src={Logo} alt="" className="imagelogo2 " /></Link>
              </div>
            </div>
          
            <div className="header-item item-center">
              <div className="menu-overlay"></div>
              <nav className="menu">
                <div className="mobile-menu-head" >
                  <div className="go-back">
                    <i className="fa fa-angle-left" />
                  </div>
                  <div className="current-menu-title" />
                  <div className="mobile-menu-close">X</div>
                </div>
       
                <ul className="menu-main">
                 
                  <li className="menu-item-has-children " style={{marginLeft:'20px'}}>
                    <a href="#" className="iconnone" style={{textDecoration:'none'}}>
                      ABOUT US <KeyboardArrowDownIcon />
                    </a>
                    <div className="sub-menu mega-menu mega-menu-column-4">
                      <div className="" style={{ textAlign: "LEFT" }}>
                        <About />
                      </div>
                    </div>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="#"  className="iconnone" style={{textDecoration:'none'}}>
                      CAPABILITIES <KeyboardArrowDownIcon />
                    </a>
                    <div className="sub-menu mega-menu mega-menu-column-4">
                      <div className="" style={{ textAlign: "LEFT" }}>
                        <Capability />
                      </div>
                    </div>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="#"  className="iconnone" style={{textDecoration:'none'}}>
                      DEVELOPMENT <KeyboardArrowDownIcon />
                    </a>
                    <div className="sub-menu mega-menu mega-menu-column-4">
                     <DevSidebar/>
                    </div>
                  </li>
              
                  <li className="menu-item-has-children">
                    <a href="#"  className="iconnone" style={{textDecoration:'none'}}>
                      HIRE DEVELOPER <KeyboardArrowDownIcon />
                    </a>
                    <div className="sub-menu mega-menu mega-menu-column-4">
                      {/* <SideBar/> */}
                     <HireDevSidebar/>
                    </div>
                  </li>

               
                  <li className="menu-item-has-children">
                    <a href="#"  className="iconnone" style={{textDecoration:'none'}}>
                      SOLUTIONS <KeyboardArrowDownIcon />
                    </a>
                    <div
                      className="sub-menu mega-menu mega-menu-column-4"
                      style={{ textAlign: "left" }}
                    >
                      <Solutions />
                    </div>

                  </li>
                </ul>
              
              </nav>

            </div>
        
          </div>
          <div style={{display:'flex',float:'right'}}>
   
          <Link to="/Schedule" style={{textDecoration:'none',color:'transparent'}}><span style={{float:'right' }} className="spa"> <button className="navbtn1">Request a Demo</button></span></Link>
          <Link to="/Contact" style={{textDecoration:'none',color:'transparent'}}><span style={{float:'right' }} className="spa"> <button className="navbtn">Lets Connect</button></span></Link>

          </div>
          

        </div>
      </header>
    </>
  );
};

export default NewHeader;

import React from "react";
import "../Styles/DevlopnerPage.css";
import mainAbout from "../Assets/shopdeveop2.png";
import Contactsub from "./Contactsub";
import { HashLink } from "react-router-hash-link";
import Faq from "./Faq";
import Flag from "./Flag";
import NewFaq from "./NewFaq";
import Hidemob from "../Assets/pix.png";
const SalesIntregation = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <section className="Hero-Shopfy-section-1 All-hero-main Section for-baground-images">
        <div className="container p-5 main-first-hero-all-content">
          <div className="row p-2">
            <div className="col-lg-8 side-heading-para-content">
              <h1 className="main-hero-h1 py-3">
                Salesforce Integration Services
              </h1>
              <p className="main-hero-para-main-div">
                Maximize the potential of Salesforce CRM through our
                comprehensive Salesforce Integration Services, enabling seamless
                connectivity and data consolidation across your business
                systems. Leverage the expertise of our Salesforce ISV partner to
                integrate Salesforce with your existing applications, creating a
                unified and efficient ecosystem.
              </p>
              <div className="btnnn py-4">
                <HashLink to={"#contact-us"}>
                  <button className="main-home-shopyt-btn ">
                    Request A Free Consultation
                  </button>
                </HashLink>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </section>

      {/* New second Section */}

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2-">
                  Empower, Connect, and Personalize through Custom Salesforce
                  Integrations
                </h2>
                <p className="about-main-content-parap">
                  In this digital era, it is crucial to connect and consolidate
                  data from various sources. Why not consider integrating
                  Salesforce with your existing systems to streamline business
                  processes and facilitate access to vital information?<br></br>{" "}
                  <br></br>We are ready to support you in optimizing your
                  business achievements through tailored integration solutions.
                  Whether you aim to enhance the efficiency of your sales and
                  marketing processes or require seamless data synchronization
                  between Salesforce and other applications, our team of experts
                  can seamlessly fulfill your business objectives.<br></br>{" "}
                  <br></br>
                  With over ten years of experience in elevating customer
                  experiences and expanding CRM capabilities within specified
                  timelines, we are positioned as your reliable partner for
                  Salesforce integration services.<br></br>
                  <br></br>
                  As a dependable Salesforce integration firm, we empower you to
                  unlock the full potential of your business data. Reach out to
                  us for all your Salesforce integration requirements,
                  regardless of the size of your business. We are committed to
                  delivering customized solutions!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mobileimg">
          <img src={Hidemob} />
        </div>
      </section>

      <Contactsub />

      <section className="main-services-card-main-div p-5 ">
        <div className="contianer p-md-5 ">
          <div className="row p-2">
            <h1 className="main-serv-ces-h1-head">
              Experience Our Salesforce Integration Solutions
            </h1>

            <p className="main-services-para-main-card-top py-3">
              Transform your business processes with our range of Salesforce
              integration services.
            </p>

            <div className="col-md-4 col-sm-8 card-main-div-main-card ">
              <p className="card-top-main">Custom Integration Development</p>
              <p className="main-top-bottom-content">
                As your trusted Salesforce ISV partner, we offer end-to-end
                custom integration development services, ensuring seamless
                connectivity between Salesforce and your preferred applications.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Data Synchronization</p>
              <p className="main-top-bottom-content">
                We enable real-time or scheduled data synchronization between
                Salesforce and your existing systems, ensuring data consistency
                and accuracy across your entire ecosystem.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">API Development and Integration</p>
              <p className="main-top-bottom-content">
                Our experts develop custom APIs and integrate them with
                Salesforce to enable smooth data exchange and streamline
                workflows..
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Legacy System Integration</p>
              <p className="main-top-bottom-content">
                We assist in integrating Salesforce with your legacy systems,
                allowing you to leverage the power of Salesforce while
                preserving your existing investments.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid contt">
          <div className="container" style={{ textAlign: "center" }}>
            <h1 className="arrivals-head-main-top-my-bottom">
              Our Salesforce Integration Approach
            </h1>
            <p className="arrival-main-bottom-top-head">
              As your trusted Salesforce integration partner, we involve you
              throughout the entire process. Here's our streamlined integration
              approach.
            </p>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Consultation</p>
                  <p className="arricval-card-content-main-last-top">
                    Our experts gather crucial information about your current
                    systems and requirements for a successful migration to
                    Salesforce.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Planning</p>
                  <p className="arricval-card-content-main-last-top">
                    We create a comprehensive migration roadmap that includes
                    data mapping, process alignment, resource allocation, and
                    timeline considerations.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Development</p>
                  <p className="arricval-card-content-main-last-top">
                    Our team develops custom integration solutions, ensuring
                    seamless connectivity and data exchange between Salesforce
                    and your applications.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    Testing and Validation
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Rigorous testing and validation processes are conducted to
                    ensure the accuracy and reliability of the integration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Why Choose Meetintel ltd for Salesforce Integration
                  Services?
                </h2>
                <p className="h2-h3-h4">
                  With over 16 years of industry experience, we specialize in
                  providing customized solutions to businesses across various
                  industry verticals. Here are the advantages of teaming up with
                  us for Salesforce integration services.
                </p>
                <ul className="newul">
                  <li>
                    Experienced and Certified Salesforce Integration Experts
                  </li>
                  <li>
                    Improved Operational Efficiency through Seamless
                    Connectivity
                  </li>

                  <li>Budget-Friendly Solutions</li>
                  <li>Adaptable Engagement Models</li>
                  <li>Robust Data Safety and Security</li>
                  <li>Timely Project Delivery</li>
                  <li>Efficient Communication Channels</li>
                  <li>Agile Methodology Implementation</li>

                  <li> Rigorous Quality Assurance</li>
                  <li>Complete Customer Satisfaction</li>
                  <li>Round-the-Clock Support and Maintenance</li>
                </ul>
                <HashLink to={"#contact-us"}>
                  <button className="bolet-btn-main">
                    Request Consultation
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container cont container my-5"
        style={{ padding: "15px" }}
      >
        <h4 className="head3">Frequently Asked Questions</h4>
        <h6 className="Everything my-4">
          Everything you need to know about the product and billing.
        </h6>

        <div className="accordion w-100 cont2" id="basicAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Why are Salesforce Integration Services essential for
                  businesses?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                <p>
                  Salesforce Integration Services provide seamless connectivity
                  between Salesforce and other applications, enabling data
                  synchronization and streamlined workflows.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  Does your company have certified Salesforce integration
                  experts?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, we have certified Salesforce integration experts who
                specialize in integrating Salesforce with various systems and
                applications. Hire our experts for seamless integration
                solutions.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  What are the benefits of Salesforce Integration Services?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Salesforce Integration Services offer improved data accuracy,
                enhanced productivity, streamlined processes, unified data view,
                and better decision-making capabilities.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne1"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  How does Salesforce Integration benefit different departments?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne1"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Salesforce Integration benefits sales teams by providing
                real-time customer data, marketing teams with integrated
                campaign management, and service teams with comprehensive
                customer insights.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo1"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  Which integration methods does your company employ?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo1"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                We employ a variety of integration methods, including
                point-to-point integration, middleware integration, API
                integration, and third-party integration tools, to meet your
                specific integration requirements.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree1"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  What other services does Meetintel ltd offer alongside
                  Salesforce Integration?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree1"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                In addition to Salesforce Integration, we offer consulting,
                implementation, customization, migration, and ongoing support
                services to ensure your Salesforce ecosystem operates
                seamlessly.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default SalesIntregation;

import React from "react";
import "../Styles/DevlopnerPage.css";
import mainAbout from "../Assets/shopdeveop2.png";
import Contactsub from "./Contactsub";
import { HashLink } from "react-router-hash-link";
import NewFaq from "./NewFaq";
import Flag from "./Flag";
import Faq from "./Faq";
import Hidemob from "../Assets/pix.png";
const SalesConsulting = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <section className="Hero-Shopfy-section-1 All-hero-main Section for-baground-images1">
        <div className="container p-5 main-first-hero-all-content">
          <div className="row p-2">
            <div className="col-lg-8 side-heading-para-content">
              <h1 className="main-hero-h1 py-3">
                Salesforce Consulting Services
              </h1>
              <p className="main-hero-para-main-div">
                New technologies and business models challenge the status quo.
                With the right team and guidance, you can seize opportunities in
                evolving customer expectations. Our certified Salesforce
                consultants help overcome CRM challenges and achieve exceptional
                results. We offer comprehensive Salesforce consulting services,
                including development, migration, customization, and
                implementation.
              </p>
              <div className="btnnn py-4">
                <HashLink to={"#contact-us"}>
                  <button className="main-home-shopyt-btn ">
                    Request A Free Consultation
                  </button>
                </HashLink>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </section>

      {/* New second Section */}

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2-">
                  The Impact of Reliable Salesforce Consulting Companies
                </h2>
                <p className="about-main-content-parap">
                  Partnering with a trusted Salesforce consulting company
                  empowers your business with data-driven strategies to maximize
                  ROI. With expertise in development, problem-solving, and
                  digital transformation, our Salesforce ISV partnership is
                  instrumental in shaping your future.<br></br> <br></br>Our
                  Salesforce consulting services offer the flexibility to choose
                  the ideal solution within your budget. We assess your business
                  needs, recommend suitable technologies, tools, and features,
                  and guide you through the development journey. <br></br>{" "}
                  <br></br>
                  Through collaborative innovation, we co-create solutions that
                  enhance customer engagement, driving your business forward.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mobileimg">
          <img src={Hidemob} />
        </div>
      </section>

      <Contactsub />

      <section className="main-services-card-main-div p-5  ">
        <div className="contianer p-md-5" style={{ marginTop: "50px" }}>
          <div className="row p-2">
            <h1 className="main-serv-ces-h1-head">
              Our Salesforce consulting services offer
            </h1>
            <center>
              <p className="main-services-para-main-card-top py-3">
                Through collaborative innovation, we co-create solutions that
                enhance customer engagement, driving your business forward.
              </p>
            </center>
            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Salesforce Development Consulting</p>
              <p className="main-top-bottom-content">
                As a Salesforce consulting company, we offer expert guidance to
                tailor the perfect solution for your business development needs.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">
                Salesforce Implementation Consulting
              </p>
              <p className="main-top-bottom-content">
                Our Salesforce implementation consultants assess your system and
                provide recommendations to enhance workflows across different
                platforms and environments.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">
                Salesforce Customization Consulting
              </p>
              <p className="main-top-bottom-content">
                Leverage our Salesforce consulting services to enhance business
                efficiency, productivity, and ROI through the implementation of
                custom features and modules.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Salesforce Migration Consulting</p>
              <p className="main-top-bottom-content">
                Consult our certified Salesforce CRM consultants for expert
                advice on system and data migration. We'll assist you with a
                well-structured plan to migrate data from legacy systems to
                Lightning apps.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Salesforce Integration Consulting</p>
              <p className="main-top-bottom-content">
                Count on our Salesforce consulting services to select the most
                suitable third-party extensions, plugins, and themes for your
                solution. We'll guide you through custom integrations to
                optimize your Salesforce environment.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid contt">
          <div className="container" style={{ textAlign: "center" }}>
            <h1 className="arrivals-head-main-top-my-bottom">
              Why Opting for Salesforce Consulting Services is the Right Move
            </h1>
            <p
              className="main-services-para-main-card-top py-3"
              style={{ color: "white" }}
            ></p>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    {" "}
                    Thorough Risk Assessment
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Salesforce consultants take concrete measures to ensure that
                    your solution is risk-free and equipped to handle real-time
                    challenges. They address process irregularities and overcome
                    your business hurdles.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    {" "}
                    Industry-Specific Expertise
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Salesforce consultants possess extensive knowledge in
                    managing Salesforce CRM processes, implementing
                    configurations, and catering to industry-specific
                    requirements, along with expertise in other CRM platforms.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    Enhanced Productivity
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    With Salesforce consulting services, you can adopt a
                    proactive approach to meet evolving customer relationships,
                    while experts design and implement strategic processes
                    tailored to your business needs.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    {" "}
                    Improved Efficiency
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Partnering with a reputable Salesforce consulting company
                    enables you to close deals faster. Certified Salesforce
                    consultants possess a deep understanding of the CRM system,
                    ensuring smooth implementation and development processes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  The Benefit of Trusting Meetintel ltd' Salesforce
                  Consulting Services
                </h2>
                <p className="h2-h3-h4">
                  At Meetintel ltd, a prominent Salesforce consulting firm
                  in India, we offer a range of advantages when it comes to
                  leveraging our services. Our team of skilled Salesforce
                  experts is dedicated to guiding you seamlessly from the
                  initial concept to successful implementation. By employing
                  various strategies, we ensure a streamlined execution and
                  deliver outstanding results.
                </p>
                <ul className="newul">
                  <li>Forward-thinking Outlook</li>
                  <li>Profound Knowledge of the Industry</li>

                  <li>Implementation Aligned with your Business Objectives</li>
                  <li>Comprehensive Assessment</li>
                  <li>Targeted Functional Solutions</li>
                  <li>In-depth Collaboration Sessions</li>
                  <li>Timely Project Completion</li>
                  <li>Competitive Pricing Structure</li>
                  <li>Ongoing Maintenance and Support</li>
                  <li>Salesforce Implementation</li>
                </ul>
                <HashLink to={"#contact-us"}>
                  <button className="bolet-btn-main">
                    Request Consultation
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container cont container my-5"
        style={{ padding: "15px" }}
      >
        <h4 className="head3">Frequently Asked Questions</h4>
        <h6 className="Everything my-4">
          Everything you need to know about the product and billing.
        </h6>

        <div className="accordion w-100 cont2" id="basicAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Why is Salesforce consulting important for my organization?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                <p>
                  Salesforce consulting brings valuable expertise, best
                  practices, and strategic guidance to optimize your Salesforce
                  implementation.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  Does your company have certified Salesforce consultants?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, we have certified Salesforce consultants with deep
                knowledge and experience in Salesforce solutions. Hire our
                certified Salesforce consultants for expert guidance.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  What is the role of a Salesforce consulting company?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                A Salesforce consulting company provides end-to-end services,
                including Salesforce implementation, customization, integration,
                data migration, training, and ongoing support.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne1"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Which industries do you cater to with Salesforce consulting
                  services?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne1"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                We cater to various industries such as healthcare, finance,
                retail, manufacturing, and more with our Salesforce consulting
                services.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo1"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  What are the benefits of Salesforce consulting?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo1"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Salesforce consulting helps streamline business processes,
                improve customer relationships, enhance sales and marketing
                efforts, and drive overall growth and efficiency.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree1"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  What methodologies does your company follow for Salesforce
                  consulting?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree1"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                We follow industry-standard methodologies like Agile and
                Waterfall to ensure successful Salesforce consulting projects.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default SalesConsulting;

import React, { useEffect } from "react";
import "../Styles/Solution.css";
import img1sol from "../Assets/sol/Group.png";
import sol3 from "../Assets/solo3pic.png";
import "bootstrap/dist/css/bootstrap.css";
import img3 from "../Assets/images/client_connect_app_img.png";
import img4 from "../Assets/images/client_keyboard_app.png";
import { Link } from "react-router-dom";
import Flag from "./Flag";
import Some from "../Assets/notedownsome.png";
import Some2 from "../Assets/some2.png";
import Some3 from "../Assets/some3.png";
import Some4 from "../Assets/some4.png";
import Some5 from "../Assets/some5.png";
import Some6 from "../Assets/some6.png";
import NewFaq from "./NewFaq";
function Solution3() {

  return (
    <div style={{ overflow: 'hidden' }}>
      <div className="banner_Solution3">
        <div className="Solutionbanner1_section">
          <h2>Revolutionizing School Management with AI-Powered Features</h2>
          <p>
            CognitiveEdu, developed by Meetintel ltd, is a cutting-edge
            School Management System equipped with advanced AI features. It
            revolutionizes school administration, teaching, and learning by
            offering intelligent attendance management, personalized learning
            paths, smart grading, data analytics, AI-powered student support,
            automated administrative tasks, parent communication, and resource
            optimization.
          </p>
        </div>
      </div>
      <section className="main_body_soltions">
        <section className="section1_solution">
          <div className="section1_content">
            <h3 style={{ color: "#3D4DAE" }}>
              Empowering Educational Institutions with AI-Driven Excellence in
              Education
            </h3>
            <p style={{ color: "#686868", fontSize: "14px" }}>
              CognitiveEdu enhances student performance, streamlines
              administrative processes, fosters collaboration, enables
              data-driven decision making, delivers personalized learning
              experiences, optimizes resource allocation, and strengthens
              school-home connectivity. It's a comprehensive solution that
              empowers educational institutions with AI-driven capabilities for
              excellence in education.
            </p>
          </div>
          <div className="section2_img">
            <img src={sol3} className="imgsizing_sol2" />
          </div>
        </section>
        <section className="section2_solution">
          <h2
            style={{
              color: "#3D4DAE",
              marginBottom: "40px",
              fontWeight: "bolder",
            }}
          >
            Features of AI-Powered
          </h2>
          <div className="card_container_solu1">
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intelligent Attendance Management
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Utilize AI-based facial recognition technology to automate
                attendance tracking and reduce manual effort.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Personalized Learning Paths
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Leverage AI algorithms to create personalized learning paths for
                each student based on their individual strengths, weaknesses,
                and learning style.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Smart Grading and Assessment
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Automate the grading and assessment process using AI-powered
                tools.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intelligent Data Analytics
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                }}
              >
                Harness the power of AI-driven analytics to gain valuable
                insights from student and administrative data.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                AI-Powered Student Support
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                ognitiveEdu offers AI chatbots and virtual assistants to provide
                round-the-clock support to students.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Automated Administrative Tasks
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Streamline administrative tasks with CognitiveEdu's AI
                automation capabilities.
              </p>
            </div>

            <div className="btn_sol1">
              <Link to={"/Contact"}>
                <button className="btn_sub_col1">Request A Demo</button>
              </Link>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{
          backgroundColor: "#F5F5F5",
          paddingTop: "0px",
          paddingBottom: "30px",
          marginTop: '5%'
        }}
        className="sixcard2" >
        <div className="container ppp" style={{ maxWidth: "1200px" }}>
          <h3
            style={{
              color: "#3D4DAE",
              fontSize: "48px",
              fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              marginLeft: "20px",
              paddingTop: '30px',
              marginBottom: "40px",
            }}
          >
            Benefits of AI-Powered
          </h3>

          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some} style={{ marginBottom: "15px" }} />
                <h4>Enhanced Student Performance</h4>
                <p>
                  CognitiveEdu empowers educators to personalize learning experiences, identify individual student needs, and provide targeted support, accessible through the user-friendly mobile app.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some2} style={{ marginBottom: "15px" }} />
                <h4>Streamlined Administrative Processes</h4>
                <p>
                  Automating administrative tasks with CognitiveEdu saves time and effort for school staff, accessible through the user-friendly mobile app.
                </p>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some6} style={{ marginBottom: "15px" }} />
                <h4>Improved Parent-Teacher Collaboration</h4>
                <p>
                  CognitiveEdu facilitates transparent and effective communication between parents and teachers, accessible through the user-friendly mobile app.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some3} style={{ marginBottom: "15px" }} />
                <h4>Data-Driven Decision Making</h4>
                <p>
                  The AI-driven analytics capabilities of CognitiveEdu provide valuable insights for informed decision making, accessible through the user-friendly mobile app.
                </p>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some4} style={{ marginBottom: "15px" }} />
                <h4>Personalized Learning Experiences</h4>
                <p>
                  CognitiveEdu tailors learning paths based on individual student needs and learning styles, accessible through the user-friendly mobile app.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some5} style={{ marginBottom: "15px" }} />
                <h4>Efficient Resource Allocation</h4>
                <p>
                  CognitiveEdu optimizes resource allocation by analyzing student enrollment, course demand, and teacher availability, accessible through the user-friendly mobile app.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className=" d-none d-sm-block ">
        <div className="empowering_business mt-5 ">
          <div className="text-center">
            <h3 className="text-askari-primary fw-bold">
              Revolutionizing Education with AI-Powered School Management System
              for Personalized Learning and Seamless Connectivity
            </h3>
            <p className="mt-3" style={{ color: 'white' }}>
              CognitiveEdu, the AI-powered School Management System by Meetintel
              Technologies, revolutionizes educational institutions by enhancing
              student learning, streamlining administrative processes, promoting
              collaboration, and providing actionable insights for improved
              decision making. With its advanced features and numerous benefits,
              CognitiveEdu empowers schools to deliver a personalized,
              efficient, and effective educational experience for students,
              parents, and educators alike. The system also includes a
              user-friendly mobile app for convenient access to the system's
              features and functionalities, ensuring seamless connectivity and
              empowering users on the go
            </p>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
}

export default Solution3;
